import packageService from "../services/packageService";

export const LOAD_PACKAGES = '@Packages/loadAll';
export const LOAD_PACKAGES_START = '@Packages/loadAll/start';
export const LOAD_PACKAGES_FAILURE = '@Packages/loadAll/error';

export function loadPackages(siteId) {
  return (dispatch) => {
    dispatch({type: LOAD_PACKAGES_START});
    return packageService.getPackages(siteId).then(data => {
      dispatch({type: LOAD_PACKAGES, payload: data});
    }).catch(_ => {
      dispatch({type: LOAD_PACKAGES_FAILURE});
    })
  }
}

export function reorderPackages(siteId, packages) {
  return (dispatch) => {
    return packageService.reorderPackages(siteId, packages)
        .then(_ => dispatch(loadPackages(siteId)))
        .catch(_ => {
          dispatch({type: LOAD_PACKAGES_FAILURE});
        });
  }

}
