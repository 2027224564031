import atosService from "src/services/atosService";
import splashPageService from "src/services/splashPageService";

export const SET_VALUE = "@SplashPage/setValue";
export const RESET = "@SplashPage/setValue";
export const LOAD_CONFIG = "@SplashPage/load";
export const LOAD_CONFIG_SUCCESS = "@SplashPage/load/success";
export const LOAD_CONFIG_FAILURE = "@SplashPage/load/failure";
export const LOAD_EXTRA_CONFIG_SUCCESS = "@SplashPage/load/extra/success";
export const LOAD_EXTRA_CONFIG_FAILURE = "@SplashPage/load/extra/failure";

export const ADD_FIELD = "@SplashPage/addField";
export const SET_FIELD_VALUE = "@SplashPage/setFieldValue";
export const REMOVE_FIELD = "@SplashPage/removeField";
export const REORDER_FIELD = "@SplashPage/reorderField";

export const SET_ACCOUNT_FIELD_VALUE =
  "@SplashPage/payment/setAccountFieldValue";
export const LOAD_ACCOUNT_FIELD_VALUE_SUCCESS =
  "@SplashPage/payment/loadAccountFieldValue/success";

export const setValue = (name, value) => {
  return {
    type: SET_VALUE,
    payload: {
      name,
      value,
    },
  };
};
const reset = () => ({
  type: RESET,
});
export function addField(fieldType) {
  return {
    type: ADD_FIELD,
    payload: fieldType,
  };
}

export function removeField(index) {
  return {
    type: REMOVE_FIELD,
    payload: { index },
  };
}

export function reorderField(sourceIndex, targetIndex) {
  return {
    type: REORDER_FIELD,
    payload: {
      sourceIndex,
      targetIndex,
    },
  };
}

export function setFieldValue(index, name, value) {
  return {
    type: SET_FIELD_VALUE,
    payload: {
      index,
      name,
      value,
    },
  };
}
export function setAccountFieldValue(index, name, value) {
  return {
    type: SET_ACCOUNT_FIELD_VALUE,
    payload: {
      index,
      name,
      value,
    },
  };
}

export function getSplashPageActions(dispatch) {
  const setBackGroundColor = (value) =>
    dispatch(setValue("backgroundColor", value));
  const setGridBackgroundColor = (value) =>
    dispatch(setValue("gridBackgroundColor", value));
  const setLogo = (value) => dispatch(setValue("logo", value));
  const setBackgroundImage = (value) =>
    dispatch(setValue("backgroundImage", value));
  const setAnchorEl = (value) => dispatch(setValue("anchorEl", value));
  const setSelected = (value) => dispatch(setValue("selected", value));
  const setTos1 = (value) => dispatch(setValue("tos1", value));
  const setBottomTextColor = (value) => dispatch(setValue("bottomtext_color", value));
  const setTos2 = (value) => dispatch(setValue("tos2", value));
  const setTos3 = (value) => dispatch(setValue("tos3", value));
  const setTos4 = (value) => dispatch(setValue("tos4", value));
  const setTnc = (value) => dispatch(setValue("tnc", value));
  const setPrivacyPolicy = (value) =>
    dispatch(setValue("privacyPolicy", value));
  const setPoweredByText = (value) =>
    dispatch(setValue("poweredByText", value));
  const setPoweredByLogo = (value) =>
    dispatch(setValue("poweredByLogo", value));
  const setBodyConfig = (value) => dispatch(setValue("bodyConfig", value));
  const setGridBorderRadius = (value) =>
    dispatch(setValue("gridBorderRadius", value));
  const setLogoAlign = (value) => dispatch(setValue("logoAlign", value));
  const setLogoSize = (value) => dispatch(setValue("logoSize", value));
  const setBodyFontFamily = (value) =>
    dispatch(setValue("bodyFontFamily", value));
  const getConfigLoader = (screen) => {
    switch (screen) {
      case "email":
        return splashPageService.getEmailConfigStyles;
      case "clickthroughlogin":
        return splashPageService.getClickthroughConfigStyles;
      case "payment_select_package":
      case "payment_login_for_paid":
      case "payment_details":
        return splashPageService.getPaymentConfigStyles;
      case "voucher":
        return splashPageService.getVoucherConfigStyles;
      case "multisitevoucher":
        return splashPageService.getMultiSiteVoucherConfigStyles;
      case "facebook":
        return splashPageService.getSocialConfigStyles;
      case "passwordlogin":
        return splashPageService.getPasswordConfigStyles;
      case "phone":
        return splashPageService.getPhoneConfigStyles;
      case "guestuser_login":
      case "guestuser_register":
        return splashPageService.getGuestUserConfigStyles;
      case 'ageconsent':
        return splashPageService.getAgeConsentConfigStyles;
      case 'multilanding':
        return splashPageService.getMultiLoginSplashPageConfig;
      case 'atos':
        return atosService.getAtosPageStyles;
      case 'wifi4eu':
        return splashPageService.getWiFi4EuConfigStyles;
      default:
        return null;
    }
  };
  const getFieldsLoader = (screen) => {
    switch (screen) {
      case "email":
        return splashPageService.loadEmailFields;
      case "clickthroughlogin":
        return splashPageService.loadClickthroughFields;
      case "payment_select_package":
      case "payment_login_for_paid":
      case "payment_details":
      case "payment":
        return splashPageService.loadPaymentFields;
      case "voucher":
        return splashPageService.loadVoucherFields;
      case "multisitevoucher":
        return splashPageService.loadMultiSiteVoucherFields;
      case "facebook":
        return splashPageService.loadSocialFields;
      case "passwordlogin":
        return splashPageService.loadPasswordFields;
      case "phone":
        return splashPageService.loadPhoneFields;
      case "guestuser_login":
        return splashPageService.loadGuestUserFields;
      case "guestuser_register":
        return splashPageService.loadGuestUserRegisterFields;
      case 'ageconsent':
        return splashPageService.loadAgeConsentFields;
      default:
        return null;
    }
  };
  function getExtraStylesLoader(screen) {
    switch (screen) {
      case 'multilanding':
        return (data) => 
          dispatch({
            type: LOAD_EXTRA_CONFIG_SUCCESS,
            payload: multiLandingDataToConfigMapper(data)
          });
      case "email":
        return (data) =>
          dispatch({
            type: LOAD_EXTRA_CONFIG_SUCCESS,
            payload: emailDataToConfigMapper(data),
          });
      case "clickthroughlogin":
        return (data) =>
          dispatch({
            type: LOAD_EXTRA_CONFIG_SUCCESS,
            payload: clickThroughDataToConfigMapper(data),
          });
      case "wifi4eu":
        return (data) => 
          dispatch({
            type: LOAD_CONFIG_SUCCESS,
            payload: wifi4euDataToConfigMapper(data),
          });
      case "payment_select_package":
      case "payment_login_for_paid":
      case "payment_details":
        return (data) =>
          dispatch({
            type: LOAD_EXTRA_CONFIG_SUCCESS,
            payload: paymentDataToConfigMapper(data),
          });
      case "voucher":
        return (data) =>
          dispatch({
            type: LOAD_EXTRA_CONFIG_SUCCESS,
            payload: voucherDataToConfigMapper(data),
          });
      case "multisitevoucher":
        return (data) =>
          dispatch({
            type: LOAD_EXTRA_CONFIG_SUCCESS,
            payload: multiSitevoucherDataToConfigMapper(data),
          });
      case "facebook":
        return (data) =>
          dispatch({
            type: LOAD_EXTRA_CONFIG_SUCCESS,
            payload: facebookDataToConfigMapper(data),
          });
      case "passwordlogin":
        return (data) =>
          dispatch({
            type: LOAD_EXTRA_CONFIG_SUCCESS,
            payload: passwordLoginDataToConfigMapper(data),
          });
      case "phone":
        return (data) =>
          dispatch({
            type: LOAD_EXTRA_CONFIG_SUCCESS,
            payload: phoneDataToConfigMapper(data),
          });
      case "guestuser_login":
      case "guestuser_register":
        return (data) =>
          dispatch({
            type: LOAD_EXTRA_CONFIG_SUCCESS,
            payload: guestUserDataToConfigMapper(data),
          });
      case "ageconsent":
        return (data) =>
          dispatch({
            type: LOAD_EXTRA_CONFIG_SUCCESS,
            payload: ageConsentDataToConfigMapper(data)
          });
      case 'atos':
        return (data) => 
          dispatch({
            type: LOAD_EXTRA_CONFIG_SUCCESS,
            payload: atosDataToConfigMapper(data),
          })
      default:
        return null;
    }
  }
  const loadPaymentAccountFields = async (siteId) => {
    try {
      const data = await splashPageService.getPaymentAccountLoginFields(siteId);
      dispatch({
        type: LOAD_ACCOUNT_FIELD_VALUE_SUCCESS,
        payload: data?.account_login_fields,
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOAD_CONFIG_FAILURE, payload: { error } });
    }
  };
  const loadFields = async (siteId, screen) => {
    const fieldLoader = getFieldsLoader(screen)
    if(fieldLoader){
      const data = await fieldLoader(siteId)
      dispatch(setValue('fields', screen === 'guestuser_register'? data.registration_fields : data.login_fields));
    }
  }
  const loadExtraConfig = async (siteId, screen) => {
    try {
      const styleLoader = getConfigLoader(screen);
      if (styleLoader) {
        const data = await styleLoader(siteId);
        getExtraStylesLoader(screen)(data);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: LOAD_CONFIG_FAILURE, payload: { error } });
    }
  };
  const loadConfig = async (siteId) => {
    try {
      dispatch({ type: LOAD_CONFIG });
      const data = await splashPageService.getSplashPageDesign(siteId);
      dispatch({
        type: LOAD_CONFIG_SUCCESS,
        payload: dataToBrandConfigMapper(data),
      });
    } catch (error) {
      dispatch({ type: LOAD_CONFIG_FAILURE, payload: { error } });
    }
  };
  const initializeFromData = (data) => {
    reset();
    setLogo(data.logo_image);
    setBackgroundImage(data.html_image);
    setBackGroundColor(data.body_color);
    setGridBackgroundColor(data.grid_color);
    setTos1(data.partialtos1_text);
    setBottomTextColor(data.bottomtext_color);
    setTos2(data.tos_text);
    setTos3(data.partialtos2_text);
    setTos4(data.privacy_text);
    setTnc(data.tos_content);
    setPrivacyPolicy(data.privacy_content);
    setPoweredByText(data.splash_page_powered_by_text);
    setPoweredByLogo(data.splash_page_powered_by_logo);
    setBodyConfig(data.body_config);
    setGridBorderRadius(data.grid_border_radius);
    setLogoAlign(data.logo_align);
    setLogoSize(data.logo_size);
    setBodyFontFamily(data.body_fontfamily);
  };
  return {
    setBackGroundColor,
    setGridBackgroundColor,
    setLogo,
    setBackgroundImage,
    setAnchorEl,
    setSelected,
    setTos1,
    setBottomTextColor,
    setTos2,
    setTos3,
    setTos4,
    setTnc,
    setPrivacyPolicy,
    setPoweredByLogo,
    setPoweredByText,
    setBodyConfig,
    setGridBorderRadius,
    setLogoAlign,
    setLogoSize,
    setBodyFontFamily,
    initializeFromData,
    loadConfig,
    loadExtraConfig,
    loadPaymentAccountFields,
    loadFields,
  };
}
const dataToBrandConfigMapper = (data) => {
  return {
    logo: data.logo_image,
    backgroundImage: data.html_image,
    backgroundColor: data.body_color,
    gridBackgroundColor: data.grid_color,
    tos1: data.partialtos1_text,
    tos1Color: data.partialtos1_color,
    tos2: data.tos_text,
    tos2Color: data.tos_color,
    tos3: data.partialtos2_text,
    tos3Color: data.partialtos2_color,
    tos4: data.privacy_text,
    tos4Color: data.privacy_color,
    tnc: data.tos_content,
    privacyPolicy: data.privacy_content,
    poweredByText: data.splash_page_powered_by_text,
    poweredByLogo: data.splash_page_powered_by_logo,
    bodyConfig: data.body_config,
    gridBorderRadius: data.grid_border_radius,
    logoAlign: data.logo_align,
    logoSize: data.logo_size,
    bodyFontFamily: data.body_fontfamily,
    bottomtext_color: data.bottomtext_color,
  }
}
export const brandConfigToPayloadMapper = (config) => {
        const payload = {};
        if(config.logo) payload.logo_image= config.logo;
        if(config.logo) payload.logo_image= config.logo;
        if(config.backgroundImage) payload.html_image= config.backgroundImage;
        if(config.backgroundColor) payload.body_color= config.backgroundColor;
        if(config.gridBackgroundColor) payload.grid_color= config.gridBackgroundColor;
        if(config.tos1) payload.partialtos1_text= config.tos1;
        if(config.bottomtext_color) payload.bottomtext_color= config.bottomtext_color;
        if(config.tos2) payload.tos_text= config.tos2;
        if(config.tos3) payload.partialtos2_text= config.tos3;
        if(config.tos4) payload.privacy_text= config.tos4;
        if(config.tnc) payload.tos_content= config.tnc;
        if(config.privacyPolicy) payload.privacy_content= config.privacyPolicy;
        if(config.bodyConfig) payload.body_config= config.bodyConfig;
        if(config.gridBorderRadius) payload.grid_border_radius= config.gridBorderRadius;
        if(config.logoAlign) payload.logo_align= config.logoAlign;
        if(config.logoSize) payload.logo_size= config.logoSize;
        if(config.bodyFontFamily) payload.body_fontfamily= config.bodyFontFamily;
        return payload;
}

const ageConsentDataToConfigMapper = (data) =>{
  return {
    fieldsColor: data.ageconsentfields_color,
    loginText: data.ageconsentlogin_text,
    fieldsBackgroundColor: data.ageconsentfields_backgroundcolor,
    loginBackgroundColor: data.ageconsentlogin_backgroundcolor,
    loginColor: data.ageconsentlogin_color,
    fieldsBorderColor: data.ageconsentfields_bordercolor,
    loginBorderColor: data.ageconsentlogin_bordercolor,
    fieldsPlaceholderColor: data.ageconsentfields_placeholdercolor,
    headingHtmlText: data.ageconsentheading_htmltext,
  }
}
export const ageConsentConfigToPayloadMapper = (config) =>{
  const payload = {};
    if(config.fieldsColor) payload.ageconsentfields_color = config.fieldsColor;
    if(config.loginText) payload.ageconsentlogin_text = config.loginText;
    if(config.fieldsBackgroundColor) payload.ageconsentfields_backgroundcolor = config.fieldsBackgroundColor;
    if(config.loginBackgroundColor) payload.ageconsentlogin_backgroundcolor = config.loginBackgroundColor;
    if(config.loginColor) payload.ageconsentlogin_color = config.loginColor;
    if(config.fieldsBorderColor) payload.ageconsentfields_bordercolor = config.fieldsBorderColor;
    if(config.loginBorderColor) payload.ageconsentlogin_bordercolor = config.loginBorderColor;
    if(config.fieldsPlaceholderColor) payload.ageconsentfields_placeholdercolor = config.fieldsPlaceholderColor;
    if(config.headingHtmlText) payload.ageconsentheading_htmltext = config.headingHtmlText;
    return payload;
}

const guestUserDataToConfigMapper = (data) => {
  return {
    fieldsColor: data.guestuserfields_color,
    fieldsBackgroundColor: data.guestuserfields_backgroundcolor,
    fieldsBorderColor: data.guestuserfields_bordercolor,
    fieldsPlaceholderColor: data.guestuserfields_placeholdercolor,
    loginText: data.guestuserlogin_text,
    loginColor: data.guestuserlogin_color,
    loginBackgroundColor: data.guestuserlogin_backgroundcolor,
    loginBorderColor: data.guestuserlogin_bordercolor,
    signupText: data.guestusersignup_text,
    signupColor: data.guestusersignup_color,
    signupBackgroundColor: data.guestusersignup_backgroundcolor,
    signupBorderColor: data.guestusersignup_bordercolor,
    signupHeadingHtmlText: data.guestusersignupheading_htmltext,
    backButtonText: data.guestuserbackbtn_text,
    backButtonBackgroundColor: data.guestuserbackbtn_backgroundcolor,
    backButtonBorderColor: data.guestuserbackbtn_bordercolor,
    backButtonColor: data.guestuserbackbtn_color,
    headingHtmlText: data.guestuserheading_htmltext,
  }
}
export const guestUserConfigToPayloadMapper = (config) =>{
  const payload = {};
  if(config.fieldsColor) payload.guestuserfields_color= config.fieldsColor;
  if(config.fieldsBackgroundColor) payload.guestuserfields_backgroundcolor= config.fieldsBackgroundColor;
  if(config.fieldsBorderColor) payload.guestuserfields_bordercolor= config.fieldsBorderColor;
  if(config.fieldsPlaceholderColor) payload.guestuserfields_placeholdercolor= config.fieldsPlaceholderColor;
  if(config.loginText) payload.guestuserlogin_text= config.loginText;
  if(config.loginColor) payload.guestuserlogin_color= config.loginColor;
  if(config.loginBackgroundColor) payload.guestuserlogin_backgroundcolor= config.loginBackgroundColor;
  if(config.loginBorderColor) payload.guestuserlogin_bordercolor= config.loginBorderColor;
  if(config.backButtonText) payload.guestuserbackbtn_text= config.backButtonText;
  if(config.backButtonBackgroundColor) payload.guestuserbackbtn_backgroundcolor= config.backButtonBackgroundColor;
  if(config.backButtonBorderColor) payload.guestuserbackbtn_bordercolor= config.backButtonBorderColor;
  if(config.backButtonColor) payload.guestuserbackbtn_color= config.backButtonColor;
  if(config.headingHtmlText) payload.guestuserheading_htmltext= config.headingHtmlText;
  if(config.signupText) payload.guestusersignup_text = config.signupText;
  if(config.signupColor) payload.guestusersignup_color = config.signupColor;
  if(config.signupBackgroundColor) payload.guestusersignup_backgroundcolor = config.signupBackgroundColor;
  if(config.signupBorderColor) payload.guestusersignup_bordercolor = config.signupBorderColor;
  if(config.signupHeadingHtmlText) payload.guestusersignupheading_htmltext = config.signupHeadingHtmlText;
  return payload;
}

const phoneDataToConfigMapper = (data) => {
  return {
    fieldsColor: data.phonefields_color,
    loginText: data.phonelogin_text,
    fieldsBackgroundColor: data.phonefields_backgroundcolor,
    loginBackgroundColor: data.phonelogin_backgroundcolor,
    loginColor: data.phonelogin_color,
    fieldsBorderColor: data.phonefields_bordercolor,
    loginBorderColor: data.phonelogin_bordercolor,
    fieldsPlaceholderColor: data.phonefields_placeholdercolor,
    headingHtmlText: data.phoneheading_htmltext,
    backButtonBackgroundColor: data.phonebackbtn_backgroundcolor,
    backButtonText: data.phonebackbtn_text,
    backButtonBorderColor: data.phonebackbtn_bordercolor,
    backButtonColor: data.phonebackbtn_color,
    phoneOTPButtonColor: data.phoneotpbtn_color,
    phoneOTPButtonBorderColor: data.phoneotpbtn_bordercolor,
    phoneOTPButtonBackgroundColor: data.phoneotpbtn_backgroundcolor,
    phoneOTPButtonText: data.phoneotpbtn_text,
    phoneOTPButtonResendText: data.phoneotpbtn_resend_text,
  }
}
export const phoneConfigToPayloadMapper = (config) => {
  const payload = {};
    if(config.fieldsColor) payload.phonefields_color = config.fieldsColor;
    if(config.loginText) payload.phonelogin_text = config.loginText;
    if(config.fieldsBackgroundColor) payload.phonefields_backgroundcolor = config.fieldsBackgroundColor;
    if(config.loginBackgroundColor) payload.phonelogin_backgroundcolor = config.loginBackgroundColor;
    if(config.loginColor) payload.phonelogin_color = config.loginColor;
    if(config.fieldsBorderColor) payload.phonefields_bordercolor = config.fieldsBorderColor;
    if(config.loginBorderColor) payload.phonelogin_bordercolor = config.loginBorderColor;
    if(config.fieldsPlaceholderColor) payload.phonefields_placeholdercolor = config.fieldsPlaceholderColor;
    if(config.headingHtmlText) payload.phoneheading_htmltext = config.headingHtmlText;
    if(config.backButtonBackgroundColor) payload.phonebackbtn_backgroundcolor = config.backButtonBackgroundColor;
    if(config.backButtonText) payload.phonebackbtn_text = config.backButtonText;
    if(config.backButtonBorderColor) payload.phonebackbtn_bordercolor = config.backButtonBorderColor;
    if(config.backButtonColor) payload.phonebackbtn_color = config.backButtonColor;
    if(config.phoneOTPButtonColor) payload.phoneotpbtn_color = config.phoneOTPButtonColor;
    if(config.phoneOTPButtonBorderColor) payload.phoneotpbtn_bordercolor = config.phoneOTPButtonBorderColor;
    if(config.phoneOTPButtonBackgroundColor) payload.phoneotpbtn_backgroundcolor = config.phoneOTPButtonBackgroundColor;
    if(config.phoneOTPButtonText) payload.phoneotpbtn_text = config.phoneOTPButtonText;
    if(config.phoneOTPButtonResendText) payload.phoneotpbtn_resend_text = config.phoneOTPButtonResendText;
    return payload;
}
const passwordLoginDataToConfigMapper = (data) =>{
  return {
    fieldsColor: data.passwordfields_color,
    fieldsBackgroundColor: data.passwordfields_backgroundcolor,
    fieldsBorderColor: data.passwordfields_bordercolor,
    fieldsPlaceholderColor: data.passwordfields_placeholdercolor,
    loginText: data.passwordlogin_text,
    loginColor: data.passwordlogin_color,
    backButtonText: data.passwordbackbtn_text,
    loginBackgroundColor: data.passwordlogin_backgroundcolor,
    loginBorderColor: data.passwordlogin_bordercolor,
    headingHtmlText: data.passwordheading_htmltext,
    backButtonBackgroundColor: data.passwordbackbtn_backgroundcolor,
    backButtonBorderColor: data.passwordbackbtn_bordercolor,
    backButtonColor: data.passwordbackbtn_color,
  }
}
export const passwordLoginConfigToPayloadMapper = (config) => {
    const payload = {};
    if(config.fieldsColor) payload.passwordfields_color = config.fieldsColor;
    if(config.fieldsBackgroundColor) payload.passwordfields_backgroundcolor = config.fieldsBackgroundColor;
    if(config.fieldsBorderColor) payload.passwordfields_bordercolor = config.fieldsBorderColor;
    if(config.fieldsPlaceholderColor) payload.passwordfields_placeholdercolor = config.fieldsPlaceholderColor;
    if(config.loginText) payload.passwordlogin_text = config.loginText;
    if(config.loginColor) payload.passwordlogin_color = config.loginColor;
    if(config.backButtonText) payload.passwordbackbtn_text = config.backButtonText;
    if(config.loginBackgroundColor) payload.passwordlogin_backgroundcolor = config.loginBackgroundColor;
    if(config.loginBorderColor) payload.passwordlogin_bordercolor = config.loginBorderColor;
    if(config.headingHtmlText) payload.passwordheading_htmltext = config.headingHtmlText;
    if(config.backButtonBackgroundColor) payload.passwordbackbtn_backgroundcolor = config.backButtonBackgroundColor;
    if(config.backButtonBorderColor) payload.passwordbackbtn_bordercolor = config.backButtonBorderColor;
    if(config.backButtonColor) payload.passwordbackbtn_color = config.backButtonColor;
    return payload;
}

const facebookDataToConfigMapper = (data) =>{
  return {
    fieldsColor: data.facebookfields_color,
    fieldsBackgroundColor: data.facebookfields_backgroundcolor,
    fieldsBorderColor: data.facebookfields_bordercolor,
    fieldsPlaceholderColor: data.facebookfields_placeholdercolor,
    loginText: data.facebooklogin_text,
    loginColor: data.facebooklogin_color,
    backButtonText: data.facebookbackbtn_text,
    loginBackgroundColor: data.facebooklogin_backgroundcolor,
    loginBorderColor: data.facebooklogin_bordercolor,
    headingHtmlText: data.facebookheading_htmltext,
    backButtonBackgroundColor: data.facebookbackbtn_backgroundcolor,
    backButtonBorderColor: data.facebookbackbtn_bordercolor,
    backButtonColor: data.facebookbackbtn_color,
  };
}
export const facebookConfigToPayloadMapper = (config) => {
    const payload = {};
    if(config.fieldsColor) payload.facebookfields_color = config.fieldsColor;
    if(config.fieldsBackgroundColor) payload.facebookfields_backgroundcolor = config.fieldsBackgroundColor;
    if(config.fieldsBorderColor) payload.facebookfields_bordercolor = config.fieldsBorderColor;
    if(config.fieldsPlaceholderColor) payload.facebookfields_placeholdercolor = config.fieldsPlaceholderColor;
    if(config.loginText) payload.facebooklogin_text = config.loginText;
    if(config.loginColor) payload.facebooklogin_color = config.loginColor;
    if(config.backButtonText) payload.facebookbackbtn_text = config.backButtonText;
    if(config.loginBackgroundColor) payload.facebooklogin_backgroundcolor = config.loginBackgroundColor;
    if(config.loginBorderColor) payload.facebooklogin_bordercolor = config.loginBorderColor;
    if(config.headingHtmlText) payload.facebookheading_htmltext = config.headingHtmlText;
    if(config.backButtonBackgroundColor) payload.facebookbackbtn_backgroundcolor = config.backButtonBackgroundColor;
    if(config.backButtonBorderColor) payload.facebookbackbtn_bordercolor = config.backButtonBorderColor;
    if(config.backButtonColor) payload.facebookbackbtn_color = config.backButtonColor;
    return payload;
}
const voucherDataToConfigMapper = (data) => {
  return {
    fieldsColor: data.voucherfields_color,
    fieldsBackgroundColor: data.voucherfields_backgroundcolor,
    fieldsBorderColor: data.voucherfields_bordercolor,
    fieldsPlaceholderColor: data.voucherfields_placeholdercolor,
    loginText: data.voucherlogin_text,
    loginColor: data.voucherlogin_color,
    loginBackgroundColor: data.voucherlogin_backgroundcolor,
    loginBorderColor: data.voucherlogin_bordercolor,
    backButtonText: data.voucherbackbtn_text,
    backButtonBackgroundColor: data.voucherbackbtn_backgroundcolor,
    backButtonBorderColor: data.voucherbackbtn_bordercolor,
    backButtonColor: data.voucherbackbtn_color,
    headingHtmlText: data.voucherheading_htmltext,
  }
}

const multiSitevoucherDataToConfigMapper = (data) => {
  return {
    fieldsColor: data.multisitevoucherfields_color,
    fieldsBackgroundColor: data.multisitevoucherfields_backgroundcolor,
    fieldsBorderColor: data.multisitevoucherfields_bordercolor,
    fieldsPlaceholderColor: data.multisitevoucherfields_placeholdercolor,
    loginText: data.multisitevoucherlogin_text,
    loginColor: data.multisitevoucherlogin_color,
    loginBackgroundColor: data.multisitevoucherlogin_backgroundcolor,
    loginBorderColor: data.multisitevoucherlogin_bordercolor,
    backButtonText: data.multisitevoucherbackbtn_text,
    backButtonBackgroundColor: data.multisitevoucherbackbtn_backgroundcolor,
    backButtonBorderColor: data.multisitevoucherbackbtn_bordercolor,
    backButtonColor: data.multisitevoucherbackbtn_color,
    headingHtmlText: data.multisitevoucherheading_htmltext,
  }
}

export const voucherConfigToPayloadMapper = (config) => {
  const payload = {};
  if(config.fieldsColor) payload.voucherfields_color = config.fieldsColor;
  if(config.fieldsBackgroundColor) payload.voucherfields_backgroundcolor = config.fieldsBackgroundColor;
  if(config.fieldsBorderColor) payload.voucherfields_bordercolor = config.fieldsBorderColor;
  if(config.fieldsPlaceholderColor) payload.voucherfields_placeholdercolor = config.fieldsPlaceholderColor;
  if(config.loginText) payload.voucherlogin_text = config.loginText;
  if(config.loginColor) payload.voucherlogin_color = config.loginColor;
  if(config.loginBackgroundColor) payload.voucherlogin_backgroundcolor = config.loginBackgroundColor;
  if(config.loginBorderColor) payload.voucherlogin_bordercolor = config.loginBorderColor;
  if(config.backButtonText) payload.voucherbackbtn_text = config.backButtonText;
  if(config.backButtonBackgroundColor) payload.voucherbackbtn_backgroundcolor = config.backButtonBackgroundColor;
  if(config.backButtonBorderColor) payload.voucherbackbtn_bordercolor = config.backButtonBorderColor;
  if(config.backButtonColor) payload.voucherbackbtn_color = config.backButtonColor;
  if(config.headingHtmlText) payload.voucherheading_htmltext = config.headingHtmlText;
  return payload;
}

export const multisiteVoucherConfigToPayloadMapper = (config) => {
  const payload = {};
  if(config.fieldsColor) payload.multisitevoucherfields_color = config.fieldsColor;
  if(config.fieldsBackgroundColor) payload.multisitevoucherfields_backgroundcolor = config.fieldsBackgroundColor;
  if(config.fieldsBorderColor) payload.multisitevoucherfields_bordercolor = config.fieldsBorderColor;
  if(config.fieldsPlaceholderColor) payload.multisitevoucherfields_placeholdercolor = config.fieldsPlaceholderColor;
  if(config.loginText) payload.multisitevoucherlogin_text = config.loginText;
  if(config.loginColor) payload.multisitevoucherlogin_color = config.loginColor;
  if(config.loginBackgroundColor) payload.multisitevoucherlogin_backgroundcolor = config.loginBackgroundColor;
  if(config.loginBorderColor) payload.multisitevoucherlogin_bordercolor = config.loginBorderColor;
  if(config.backButtonText) payload.multisitevoucherbackbtn_text = config.backButtonText;
  if(config.backButtonBackgroundColor) payload.multisitevoucherbackbtn_backgroundcolor = config.backButtonBackgroundColor;
  if(config.backButtonBorderColor) payload.multisitevoucherbackbtn_bordercolor = config.backButtonBorderColor;
  if(config.backButtonColor) payload.multisitevoucherbackbtn_color = config.backButtonColor;
  if(config.headingHtmlText) payload.multisitevoucherheading_htmltext = config.headingHtmlText;
  return payload;
}

const paymentDataToConfigMapper = (data) => {
  return {
    fieldsColor: data.paymentfields_color,
    fieldsBackgroundColor: data.paymentfields_backgroundcolor,
    fieldsBorderColor: data.paymentfields_bordercolor,
    fieldsPlaceholderColor: data.paymentfields_placeholdercolor,
    loginText: data.paymentalreadypaidbtn_text,
    loginColor: data.paymentalreadypaidbtn_color,
    loginBackgroundColor: data.paymentalreadypaidbtn_backgroundcolor,
    loginBorderColor: data.paymentalreadypaidbtn_bordercolor,
    headingHtmlText: data.paymentheading_htmltext,
    cardHeadingHtmlText: data.paymentcardheading_htmltext,
    alreadyPaidHeadingHtmlText: data.paymentalreadypaidheading_htmltext,
    backButtonBackgroundColor: data.paymentbackbtn_backgroundcolor,
    backButtonBorderColor: data.paymentbackbtn_bordercolor,
    backButtonText: data.paymentbackbtn_text,
    backButtonColor: data.paymentbackbtn_color,
    payButtonBackgroundColor: data.paymentmakepaymentbtn_backgroundcolor,
    payButtonText: data.paymentmakepaymentbtn_text,
    payButtonBorderColor: data.paymentmakepaymentbtn_bordercolor,
    payButtonColor: data.paymentmakepaymentbtn_color,
    paymentGotoAccountButtonBackgroundColor: data.paymentgotoaccountbtn_backgroundcolor,
    paymentGotoAccountButtonText: data.paymentgotoaccountbtn_text,
    paymentGotoAccountButtonBorderColor: data.paymentgotoaccountbtn_bordercolor,
    paymentGotoAccountButtonColor: data.paymentgotoaccountbtn_color,
  }
}
export const paymentConfigToPayloadMapper = (config) => {
    const payload = {};
    if(config.fieldsColor) payload.paymentfields_color = config.fieldsColor;
    if(config.fieldsBackgroundColor) payload.paymentfields_backgroundcolor = config.fieldsBackgroundColor;
    if(config.fieldsBorderColor) payload.paymentfields_bordercolor = config.fieldsBorderColor;
    if(config.fieldsPlaceholderColor) payload.paymentfields_placeholdercolor = config.fieldsPlaceholderColor;
    if(config.loginText) payload.paymentalreadypaidbtn_text = config.loginText;
    if(config.loginColor) payload.paymentalreadypaidbtn_color = config.loginColor;
    if(config.loginBackgroundColor) payload.paymentalreadypaidbtn_backgroundcolor = config.loginBackgroundColor;
    if(config.loginBorderColor) payload.paymentalreadypaidbtn_bordercolor = config.loginBorderColor;
    if(config.headingHtmlText) payload.paymentheading_htmltext = config.headingHtmlText;
    if(config.cardHeadingHtmlText) payload.paymentcardheading_htmltext = config.cardHeadingHtmlText;
    if(config.alreadyPaidHeadingHtmlText) payload.paymentalreadypaidheading_htmltext = config.alreadyPaidHeadingHtmlText;
    if(config.backButtonBackgroundColor) payload.paymentbackbtn_backgroundcolor = config.backButtonBackgroundColor;
    if(config.backButtonBorderColor) payload.paymentbackbtn_bordercolor = config.backButtonBorderColor;
    if(config.backButtonText) payload.paymentbackbtn_text = config.backButtonText;
    if(config.backButtonColor) payload.paymentbackbtn_color = config.backButtonColor;
    if(config.payButtonBackgroundColor) payload.paymentmakepaymentbtn_backgroundcolor = config.payButtonBackgroundColor;
    if(config.payButtonText) payload.paymentmakepaymentbtn_text = config.payButtonText;
    if(config.payButtonBorderColor) payload.paymentmakepaymentbtn_bordercolor = config.payButtonBorderColor;
    if(config.payButtonColor) payload.paymentmakepaymentbtn_color = config.payButtonColor;
    if(config.paymentGotoAccountButtonBackgroundColor) payload.paymentgotoaccountbtn_backgroundcolor = config.paymentGotoAccountButtonBackgroundColor;
    if(config.paymentGotoAccountButtonText) payload.paymentgotoaccountbtn_text = config.paymentGotoAccountButtonText;
    if(config.paymentGotoAccountButtonBorderColor) payload.paymentgotoaccountbtn_bordercolor = config.paymentGotoAccountButtonBorderColor;
    if(config.paymentGotoAccountButtonColor) payload.paymentgotoaccountbtn_color = config.paymentGotoAccountButtonColor;
    return payload;
}

const clickThroughDataToConfigMapper = (data) => {
  return {
    loginText: data.clickthroughlogin_text,
    loginColor: data.clickthroughlogin_color,
    loginBackgroundColor: data.clickthroughlogin_backgroundcolor,
    loginBorderColor: data.clickthroughlogin_bordercolor,
    headingHtmlText: data.clickthroughheading_htmltext,
    backButtonText: data.clickthroughbackbtn_text,
    backButtonBackgroundColor: data.clickthroughbackbtn_backgroundcolor,
    backButtonBorderColor: data.clickthroughbackbtn_bordercolor,
    backButtonColor: data.clickthroughbackbtn_color,
  }
}
const wifi4euDataToConfigMapper = (data) => {
  return {
    loginText: data.wifi4eulogin_text,
    loginColor: data.wifi4eulogin_color,
    loginBackgroundColor: data.wifi4eulogin_backgroundcolor,
    loginBorderColor: data.wifi4eulogin_bordercolor,
    backButtonText: data.wifi4euloginbackbtn_text,
    backButtonBackgroundColor: data.wifi4euloginbackbtn_backgroundcolor,
    backButtonBorderColor: data.wifi4euloginbackbtn_bordercolor,
    backButtonColor: data.wifi4euloginbackbtn_color,
  }
}
export const wifi4euConfigToPayloadMapper = (config) => {
  const payload = {};
  if(config.loginText) payload.wifi4eulogin_text = config.loginText;
  if(config.loginColor) payload.wifi4eulogin_color = config.loginColor;
  if(config.loginBackgroundColor) payload.wifi4eulogin_backgroundcolor = config.loginBackgroundColor;
  if(config.loginBorderColor) payload.wifi4eulogin_bordercolor = config.loginBorderColor;
  if(config.backButtonText) payload.wifi4euloginbackbtn_text = config.backButtonText;
  if(config.backButtonBackgroundColor) payload.wifi4euloginbackbtn_backgroundcolor = config.backButtonBackgroundColor;
  if(config.backButtonBorderColor) payload.wifi4euloginbackbtn_bordercolor = config.backButtonBorderColor;
  if(config.backButtonColor) payload.wifi4euloginbackbtn_color = config.backButtonColor;
  return payload;
}
export const clickThroughConfigToPayloadMapper = (config) => {
  const payload = {};
  if(config.loginText) payload.clickthroughlogin_text = config.loginText;
  if(config.loginColor) payload.clickthroughlogin_color = config.loginColor;
  if(config.loginBackgroundColor) payload.clickthroughlogin_backgroundcolor = config.loginBackgroundColor;
  if(config.loginBorderColor) payload.clickthroughlogin_bordercolor = config.loginBorderColor;
  if(config.headingHtmlText) payload.clickthroughheading_htmltext = config.headingHtmlText;
  if(config.backButtonText) payload.clickthroughbackbtn_text = config.backButtonText;
  if(config.backButtonBackgroundColor) payload.clickthroughbackbtn_backgroundcolor = config.backButtonBackgroundColor;
  if(config.backButtonBorderColor) payload.clickthroughbackbtn_bordercolor = config.backButtonBorderColor;
  if(config.backButtonColor) payload.clickthroughbackbtn_color = config.backButtonColor;
  return payload;
}
const emailDataToConfigMapper = (data) => {
  return {
    fieldsColor: data.emailfields_color,
    loginText: data.emaillogin_text,
    fieldsBackgroundColor: data.emailfields_backgroundcolor,
    loginBackgroundColor: data.emaillogin_backgroundcolor,
    loginColor: data.emaillogin_color,
    fieldsBorderColor: data.emailfields_bordercolor,
    loginBorderColor: data.emaillogin_bordercolor,
    fieldsPlaceholderColor: data.emailfields_placeholdercolor,
    headingHtmlText: data.emailheading_htmltext,
    checkboxfield_color: data.emailcheckboxfield_color,
  }
}

export const emailConfigToPayloadMapper = (config) => {
  const payload = {};
  if(config.fieldsColor) payload.emailfields_color = config.fieldsColor;
  if(config.loginText) payload.emaillogin_text = config.loginText;
  if(config.fieldsBackgroundColor) payload.emailfields_backgroundcolor = config.fieldsBackgroundColor;
  if(config.loginBackgroundColor) payload.emaillogin_backgroundcolor = config.loginBackgroundColor;
  if(config.loginColor) payload.emaillogin_color = config.loginColor;
  if(config.fieldsBorderColor) payload.emailfields_bordercolor = config.fieldsBorderColor;
  if(config.loginBorderColor) payload.emaillogin_bordercolor = config.loginBorderColor;
  if(config.fieldsPlaceholderColor) payload.emailfields_placeholdercolor = config.fieldsPlaceholderColor;
  if(config.headingHtmlText) payload.emailheading_htmltext = config.headingHtmlText;
  if(config.checkboxfield_color) payload.emailcheckboxfield_color = config.checkboxfield_color;
  return payload;
}

const multiLandingDataToConfigMapper = (data) => {
  return {
    headingHtmlText: data.multiheading_htmltext,
    multi_btns: data.multi_btns
  }
}
export const multiLandingConfigToPayloadMapper = (config) => {
  const payload = {};
  if(config.headingHtmlText) payload.multiheading_htmltext = config.headingHtmlText;
  if(config.multi_btns) payload.multi_btns = config.multi_btns;
  return payload;
}

const atosDataToConfigMapper = (data) => {
  return {
    atossubheading_color: data.atossubheading_color,
    atosagreebtn_color: data.atosagreebtn_color,
    atosagreebtn_bordercolor: data.atosagreebtn_bordercolor,
    atosdescription_color: data.atosdescription_color,
    atosagreebtn_backgroundcolor: data.atosagreebtn_backgroundcolor,
    atosmainheading_color: data.atosmainheading_color,
    atosagreebtn_text: data.atosagreebtn_text,
  }
}
export const atosConfigToPayloadMapper = (config) => {
  const payload = {};
  if(config.atossubheading_color) payload.atossubheading_color = config.atossubheading_color;
  if(config.atosagreebtn_color) payload.atosagreebtn_color = config.atosagreebtn_color;
  if(config.atosagreebtn_bordercolor) payload.atosagreebtn_bordercolor = config.atosagreebtn_bordercolor;
  if(config.atosdescription_color) payload.atosdescription_color = config.atosdescription_color;
  if(config.atosagreebtn_backgroundcolor) payload.atosagreebtn_backgroundcolor = config.atosagreebtn_backgroundcolor;
  if(config.atosmainheading_color) payload.atosmainheading_color = config.atosmainheading_color;
  if(config.atosagreebtn_text) payload.atosagreebtn_text = config.atosagreebtn_text;
  return payload;
}
export const atosConfigToContentPayloadMapper = (config) => {
  const payload = {};
  if(config.optional_h2) payload.optional_h2 = config.optional_h2;
  if(config.gdpr_contact_h1) payload.gdpr_contact_h1 = config.gdpr_contact_h1;
  if(config.gdpr_heading_text) payload.gdpr_heading_text = config.gdpr_heading_text;
  if(config.gdpr_device_id_h1) payload.gdpr_device_id_h1 = config.gdpr_device_id_h1;
  if(config.gdpr_device_id_h2) payload.gdpr_device_id_h2 = config.gdpr_device_id_h2;
  if(config.optional_h1) payload.optional_h1 = config.optional_h1;
  if(config.gdpr_device_id_desc) payload.gdpr_device_id_desc = config.gdpr_device_id_desc;
  if(config.optional_desc) payload.optional_desc = config.optional_desc;
  if(config.gdpr_contact_h2) payload.gdpr_contact_h2 = config.gdpr_contact_h2;
  if(config.gdpr_contact_desc) payload.gdpr_contact_desc = config.gdpr_contact_desc;
  return payload;
}