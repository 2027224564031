import metaService from "../services/metaService";

export const LOAD_META = '@Global/load';


export function loadMetaData() {
  return (dispatch) => {
    metaService.getMetaData().then((response) => dispatch({
      type: LOAD_META,
      payload: response
    }));
  };
}
