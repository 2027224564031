import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';

function SiteGuard({permission, children}) {
    const {settings} = useSelector((state) => state.siteSettings);
    const {user} = useSelector(state => state.account);
    const permissions = {...settings?.permissions, ...user.permissions};

    if (permission && !permissions[permission]) {
        return <Redirect to="/app/reports/dashboard"/>;
    }

    return children;
}

SiteGuard.propTypes = {
    children: PropTypes.any
};

export default SiteGuard;
