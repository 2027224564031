import whitelistedDevicesService from "../services/whitelistedDevicesService";
import {errorMessageToString} from "../utils/axios";


export const LOAD_WHITELIST = '@Whitelist/load';
export const LOAD_WHITELIST_SUCCESS = '@Whitelist/load/success';
export const LOAD_WHITELIST_FAILURE = '@Whitelist/load/failure';
export const DELETE_WHITELIST = '@Whitelist/delete';
export const DELETE_WHITELIST_FAILURE = '@Whitelist/delete/failure';

export function loadWhitelistedDevices(siteId, pageIndex, pageSize, searchString, fromDate, toDate) {
  return (dispatch) => {
    dispatch({type: LOAD_WHITELIST});
    whitelistedDevicesService.loadAllWhitelistedDevices(pageIndex, pageSize, searchString, siteId, fromDate, toDate)
      .then(data => dispatch({
        type: LOAD_WHITELIST_SUCCESS,
        payload: {
          items: data.items,
          metadata: data.metadata
        }
      }))
      .catch(error => {
        dispatch({type: LOAD_WHITELIST_FAILURE, payload: {error: errorMessageToString(error)}})
      });
  }
}
export function deletedWhitelistedDevice(id, search) {
  return (dispatch, getState) => {
    const {guests, selectedSite} = getState();
    dispatch({type: DELETE_WHITELIST});
    whitelistedDevicesService.deleteWhitelistedDevice(selectedSite.id, id)
      .then(_ => dispatch(loadWhitelistedDevices(selectedSite.id,1, guests.metadata.per_page, search )))
      .catch(error => {
        console.log(error.statusText);
        dispatch({type: DELETE_WHITELIST_FAILURE, payload: {error: errorMessageToString(error)}})
      });
  };
}
