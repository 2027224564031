import { LoadingButton } from "@mui/lab";
import { Alert, Avatar, Box, Card, CardContent, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useState } from "react";
import { Trans } from "react-i18next";
import { IoIosCloudUpload } from 'react-icons/io';
import siteService from "src/services/siteService";
import { errorMessageToString } from "src/utils/axios";

const ImageUpload = ({ formik, t }) => {
  const [error, setError] = useState('');
  const [logoUploadInProgress, setLogoUploadInProgress] = useState(false);
  const [bgUploadInProgress, setBgUploadInProgress] = useState(false);

  const handleCaptureBackGroundImage = ({ target }) => {
    setBgUploadInProgress(true)
    siteService
      .uploadImage(target.files[0])
      .then((data) => {
        formik.handleChange({target:{name:'html_image', value: data.filelink}});
      })
      .catch((error) => setError(errorMessageToString(error)))
      .finally(() => setBgUploadInProgress(false));
  };
  const handleCaptureLogoImage = ({ target }) => {
    setLogoUploadInProgress(true)
    siteService
      .uploadImage(target.files[0])
      .then((data) => {
        formik.handleChange({target:{name:'logo_image', value:data.filelink}});
      })
      .catch((error) => setError(errorMessageToString(error)))
      .finally(() => setLogoUploadInProgress(false))
  };
  return (<>
    <Box display="flex" justifyContent="center">
      <Card sx={{bgcolor: 'transparent', mr: 5}} variant="outlined">
        <CardContent sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        {formik.values.logo_image ? (
            <Box mr={1}>
              <img width={50} src={formik.values.logo_image} alt="image" />
            </Box>
          ):<Avatar
          alt="upload image"
          variant="square"
          sx={{ width: 50, height: 50, mr: 1}}
        ><IoIosCloudUpload size={30}/></Avatar>}
        <LoadingButton loading={logoUploadInProgress} variant="contained" component="label" fullWidth size="small"  color="info">
          <Trans>Upload Logo</Trans>
          <input
            type="file"
            accept="image/*"
            onChange={handleCaptureLogoImage}
            style={{ display: "none" }}
          />
        </LoadingButton>
        </CardContent>
      </Card>
      <Card sx={{bgcolor: 'transparent'}} variant="outlined">
      <CardContent sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        {formik.values.html_image ?(
            <Box mr={1}>
              <img width={50} src={formik.values.html_image} alt="image" />
            </Box>
          ): <Avatar
          alt="upload image"
          variant="square"
          sx={{ width: 50, height: 50, mr: 1 }}
        ><IoIosCloudUpload size={30}/></Avatar>}
        <LoadingButton loading={bgUploadInProgress} variant="contained" component="label" fullWidth size="small" color="info">
          <Trans>Upload Background</Trans>
          <input
            type="file"
            accept="image/*"
            onChange={handleCaptureBackGroundImage}
            style={{ display: "none" }}
          />
        </LoadingButton>
        </CardContent>
      </Card>
    </Box>
    {error ? <Box maxWidth="sm" mt={2} mx={'auto'}>
      <Alert severity="error"><Trans>There was an error uploading your file.</Trans></Alert>
    </Box>: null}
    <Box display="flex" justifyContent="center" mt={5}>
      <FormGroup>
        <FormControlLabel checked={formik.values.skip_image}
            disabled = {formik.values.logo_image || formik.values.html_image}
            onChange={e => formik.handleChange({target:{name: 'skip_image', value: e.target.checked}})} 
            control={<Checkbox defaultChecked />} 
            label={t('Skip image upload')}
            id="skip_image_upload"
        />
      </FormGroup>
    </Box>
    </>
  );
};
export default ImageUpload;
