import axios, {mapError} from 'src/utils/axios';

const config = {
  credentials: "same-origin",
  mode: 'cors',
  headers: {
    'Accept': 'application/json'
  }
};

class SubscriptionService {
  getBillingDetails = () => new Promise((resolve, reject) => {
    const url = '/a/api/v1/billingdetails/';
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  getBillingCard = () => new Promise((resolve, reject) => {
    const url = '/a/api/v1/billingcard/';
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  saveBillingCard = (token) => new Promise((resolve, reject) => {
    const url = '/a/api/v1/billingcard/';
    axios.post(url, {token}, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  saveBillingAddress = (address) => new Promise((resolve, reject) => {
    const url = '/a/api/v1/billingdetails/';
    axios.post(url, address, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  getSubscriptionDetails = () => new Promise((resolve, reject) => {
    const url = '/a/api/v1/subscription/';
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  getPaymentHistory = () => new Promise((resolve, reject) => {
    const url = '/a/api/v1/invoices/';
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  savePlan = (planType) => new Promise((resolve, reject) => {
    const url = '/a/api/v1/subscription/';
    axios.post(url, {plan_type: planType}, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  cancelSubscription = (data) => new Promise((resolve, reject) => {
    const url = '/a/api/v1/subscription/cancel';
    axios.post(url, data, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  reactivate = () => new Promise((resolve,reject)=>{
    const url = '/a/api/v1/subscription/reactivate';
    axios.post(url,  config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  purchase = (token) => new Promise((resolve, reject) => {
    const url = '/a/api/v1/subscription/buynow';
    const payload = token ? {token} : {};
    axios.post(url, payload, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  getPlans = () => new Promise((resolve, reject) => {
    const url = '/a/api/v1/plans/';
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  getChargebeeSessionInfo = () => new Promise((resolve, reject) => {
    const url = '/a/api/v1/subscription/portal/session';
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  getChangeSubscriptionData = () => new Promise((resolve, reject) =>{
    const url = '/a/api/v1/subscription/change';
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  })
  getUpgradeSubscriptionData = () => new Promise((resolve, reject) =>{
    const url = '/a/api/v1/subscription/buynow';
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  })
  getReactivateSubscriptionData = () => new Promise((resolve, reject) =>{
    const url = '/a/api/v1/subscription/reactivate';
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  })
  updateSubscription = (planId) => new Promise((resolve, reject) => {
    const url = '/a/api/v1/subscription/change';
    axios.post(url,{plan_id: planId},  config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  })
  ugrageSubscription = (planId) => new Promise((resolve, reject) => {
    const url = '/a/api/v1/subscription/buynow';
    axios.post(url,{plan_id: planId},  config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  })
  reactivateSubscription = (planId) => new Promise((resolve, reject) => {
    const url = '/a/api/v1/subscription/reactivate';
    axios.post(url,{plan_id: planId},  config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  })
}

const subscriptionService = new SubscriptionService();
export default subscriptionService;
