import subscriptionService from "../services/subscriptionService";

export const LOAD_ALL = '[SUBSCRIPTION] Load all';
export const UPDATE_BILLING_DETAILS = '[SUBSCRIPTION] Update billing details';
export const UPDATE_BILLING_CARD = '[SUBSCRIPTION] Update billing card';
export const UPDATE_CURRENT_PLAN = '[SUBSCRIPTION] Current plan';

export function loadSubscription(callback) {
  return (dispatch) => {
    const billingPromise = subscriptionService.getBillingDetails();
    const subscriptionPromise = subscriptionService.getSubscriptionDetails();
    const paymentHistoryPromise = subscriptionService.getPaymentHistory();
    const cardPromise = subscriptionService.getBillingCard();
    Promise.all([billingPromise, subscriptionPromise, paymentHistoryPromise, cardPromise])
      .then(([billingAddress, currentPlan, billingHistory, card]) => {
        dispatch({
        type: LOAD_ALL,
        payload: {billingAddress, currentPlan, billingHistory, card}
      });
      if(callback) callback();
    });
  };
}

export function updateBillingDetails(values) {
  return (dispatch) => {
    return subscriptionService.saveBillingAddress(values).then(billingAddress => {
      dispatch({
        type: UPDATE_BILLING_DETAILS,
        payload: {billingAddress}
      })
    });
  };
}

export function updateBillingCard(result) {
  return (dispatch) => {
    return subscriptionService.saveBillingCard(result.token.id)
      .then(card => {
        dispatch({
          type: UPDATE_BILLING_CARD,
          payload: {card}
        })
      });
  };

}

export function updateCurrentPlan(plan) {
  return ({
    type: UPDATE_CURRENT_PLAN,
    payload: {plan}
  })
}
