import axios, {mapError} from '../utils/axios';

const config = {
  credentials: "same-origin",
  mode: 'cors',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
};

class SplashPageService {
  getAvailableFonts = () => new Promise((resolve, reject) => {
    const url = '/static/guest/fonts/config.json';
    axios.get(url, config).then(({data})=> resolve(data))
        .catch(error => reject(mapError(error)));
  });
  getSplashPageDesign = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/splashpage/design`;
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  saveSplashPageDesign = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/splashpage/design`;
    axios.put(url,data, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  /* ############# Email apis ##################*/
  getEmailConfigValues = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/emailconfig/`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getEmailConfigStyles = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/emailconfig/splashpage`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getEmailFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/emailconfig/fields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveEmailSplashPageConfig = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/emailconfig/splashpage`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveEmailFieldsAndSettings = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/emailconfig/`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveEmailFields = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/emailconfig/loginfields`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  loadEmailFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/emailconfig/loginfields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  /* ############# Age Consent apis ##################*/
  getAgeConsentConfigValues = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/ageconsent/`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getAgeConsentConfigStyles = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/ageconsent/splashpage`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveAgeConsentSplashPageConfig = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/ageconsent/splashpage`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveAgeConsentFieldsAndSettings = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/ageconsent/`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveAgeConsentFields = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/ageconsent/loginfields`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  loadAgeConsentFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/ageconsent/loginfields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  /* ############# Phone apis ##################*/
  getPhoneConfigValues = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/phoneconfig/`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getPhoneConfigStyles = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/phoneconfig/splashpage`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getPhoneFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/phoneconfig/fields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  savePhoneSplashPageConfig = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/phoneconfig/splashpage`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  savePhoneFieldsAndSettings = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/phoneconfig/`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  savePhoneFields = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/phoneconfig/loginfields`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  loadPhoneFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/phoneconfig/loginfields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  /* ############# Password apis ##################*/
  getPasswordConfigValues = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/passwordloginconfig/`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getPasswordConfigStyles = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/passwordloginconfig/splashpage`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getPasswordFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/passwordloginconfig/fields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  savePasswordSplashPageConfig = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/passwordloginconfig/splashpage`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  savePasswordFieldsAndSettings = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/passwordloginconfig/`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  savePasswordFields = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/passwordloginconfig/loginfields`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  loadPasswordFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/passwordloginconfig/loginfields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });

  /* ############# Payment apis ##################*/
  getPaymentConfigValues = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymentloginconfig/`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getPaymentAccountLoginFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymentloginconfig/accountfields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getPaymentConfigStyles = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymentloginconfig/splashpage`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getPaymentFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymentloginconfig/fields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  savePaymentSplashPageConfig = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymentloginconfig/splashpage`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  savePaymentFieldsAndSettings = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymentloginconfig/`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  savePaymentFields = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymentloginconfig/loginfields`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  loadPaymentFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymentloginconfig/loginfields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  disconnectStripe = (siteId) => new Promise((resolve,reject) =>{
    const url =  `/s/${siteId}/api/v1/paymentloginconfig/stripe/disconnect`;
    axios.put(url, {}, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  })

  /* ############# Social apis ##################*/
  getSocialConfigValues = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/facebookloginconfig/`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getSocialConfigStyles = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/facebookloginconfig/splashpage`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getSocialFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/facebookloginconfig/fields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveSocialSplashPageConfig = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/facebookloginconfig/splashpage`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveSocialFieldsAndSettings = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/facebookloginconfig/`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveSocialFields = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/facebookloginconfig/loginfields`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  loadSocialFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/facebookloginconfig/loginfields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  /* ############# Clickthrough apis ##################*/
  getClickthroughConfigValues = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/clickthrpughloginconfig/`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getClickthroughConfigStyles = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/clickthrpughloginconfig/splashpage`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getClickthroughFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/clickthrpughloginconfig/fields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveClickthroughSplashPageConfig = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/clickthrpughloginconfig/splashpage`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveClickthroughFieldsAndSettings = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/clickthrpughloginconfig/`;
    axios.put(url, data, config).then(data => resolve(data)).catch((error) => reject(mapError(error)));
  });
  saveClickthroughFields = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/clickthrpughloginconfig/loginfields`;
    axios.put(url, data, config).then(data => resolve(data)).catch((error) => reject(mapError(error)));
  });
  loadClickthroughFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/clickthrpughloginconfig/loginfields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });

  /* ############# WiFi 4 EU  apis #################### */

  getWiFi4EUConfigValues = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/wifi4euconfig/`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });

  getWiFi4EUFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/wifi4euconfig/fields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveWiFi4EUFieldsAndSettings = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/wifi4euconfig/`;
    axios.put(url, data, config).then(data => resolve(data)).catch((error) => reject(mapError(error)));
  });
  getWiFi4EuConfigStyles = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/wifi4euconfig/splashpage`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveWiFi4EUSplashPageConfig = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/wifi4euconfig/splashpage`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  /* ############# Guest User apis ##################*/
  getGuestUserConfigValues = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guestuserloginconfig/`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getGuestUserConfigStyles = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guestuserloginconfig/splashpage`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getGuestUserFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guestuserloginconfig/fields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveGuestUserSplashPageConfig = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guestuserloginconfig/splashpage`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveGuestUserFieldsAndSettings = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guestuserloginconfig/`;
    axios.put(url, data, config).then(data => resolve(data)).catch((error) => reject(mapError(error)));
  });
  saveGuestUserFields = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guestuserloginconfig/loginfields`;
    axios.put(url, data, config).then(data => resolve(data)).catch((error) => reject(mapError(error)));
  });
  loadGuestUserFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guestuserloginconfig/loginfields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveGuestUserRegisterFields = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guestuserloginconfig/registerfields`;
    axios.put(url, data, config).then(data => resolve(data)).catch((error) => reject(mapError(error)));
  });
  loadGuestUserRegisterFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guestuserloginconfig/registerfields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  /* ############# Voucher apis ##################*/
  getVoucherConfigValues = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/voucherloginconfig/`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getVoucherConfigStyles = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/voucherloginconfig/splashpage`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getVoucherFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/voucherloginconfig/fields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveVoucherSplashPageConfig = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/voucherloginconfig/splashpage`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveVoucherFieldsAndSettings = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/voucherloginconfig/`;
    axios.put(url, data, config).then(data => resolve(data)).catch((error) => reject(mapError(error)));
  });
  saveVoucherFields = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/voucherloginconfig/loginfields`;
    axios.put(url, data, config).then(data => resolve(data)).catch((error) => reject(mapError(error)));
  });
  loadVoucherFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/voucherloginconfig/loginfields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });

   /* ############# Multi Site Voucher apis ##################*/

  getMultiSiteVoucherConfigStyles = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/multisite_voucherloginconfig/splashpage`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  getMultiSiteVoucherFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/multisite_voucherloginconfig/fields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveMultiSiteVoucherSplashPageConfig = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/multisite_voucherloginconfig/splashpage`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveMultiSiteVoucherFields = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/multisite_voucherloginconfig/loginfields`;
    axios.put(url, data, config).then(data => resolve(data)).catch((error) => reject(mapError(error)));
  });
  loadMultiSiteVoucherFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/multisite_voucherloginconfig/loginfields`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });

  /* ############# Multi Login apis ##################*/
  getMultiLoginSplashPageConfig = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/multilanding/splashpage`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
  saveMultiLoginSplashPageConfig = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/multilanding/splashpage`;
    axios.put(url, data, config).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  //----------------------------------------------------------------
  saveSplashPageConfig = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/splashpage/design`;
    axios.put(url, data, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  uploadImage = (siteId, file) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/splashpage/landingfile`;
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      credentials: "same-origin",
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'content-type': 'multipart/form-data'
      }
    };
    axios.post(url, formData, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));

  });
  getThumbnails = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/splashpage/thumbnails`;
    axios.get(url, config).then(({data}) => resolve(data)).catch(error => reject(mapError(error)));
  });
}

const splashPageService = new SplashPageService();
export default splashPageService;
