import {
  ADD_FIELD,
  REMOVE_FIELD,
  REORDER_FIELD,
  RESET,
  SET_FIELD_VALUE,
  SET_VALUE
} from "../actions/splashPageAdvancedActions";
import {SET_ACCOUNT_FIELD_VALUE} from "../actions/paymentSettingsSplashActions";

const defaultTitles = {
  email: 'Email',
  phonenumber: 'Phone Number',
  firstname: 'First Name',
  lastname: 'Last Name',
  gender: 'Gender',
  birthday: 'Birthday',
  consent: 'I like to receive newsletters in my email',
  ukpostcode: 'UK Postcode',
  custom_text: 'Custom Text',
  custom_number: 'Custom Number',
  custom_checkbox: 'Text to be shown on the check box',
  custom_select: 'Select',
  otp: 'OTP',
  country: 'Country'
};

function isMandatory(fieldType) {
  return fieldType === 'otp';
}

const defaultField = (fieldType) => {
  return ({
    "format": fieldType === 'birthday' ? 'DD/MM/YYYY' : '',
    "mandatory": isMandatory(fieldType),
    "default_checked": false,
    "female_option_text": fieldType === 'gender' ? 'Female' : '',
    "male_option_text": fieldType === 'gender' ? 'Male' : '',
    "field_type": fieldType,
    "regex": '',
    "title": defaultTitles[fieldType],
    "options_list": []
  });
};

function replaceFieldValue(fields, {index, name, value}) {
  return fields.map((f, i) => index === i ? ({
    ...f,
    [name]: value
  }) : f);
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const initialState = {
  enableGracePeriod: false,
  reLoginPolicy: 'always',
  sessionLimitControl: 0,
  sessionOverridePassword: '',
  timeLimit: 0,
  dataLimit: 0,
  speedUploadLimit: 0,
  speedDownloadLimit: 0,
  enableVerification: false,
  gracePeriodDuration: 0,
  fields: [],
  accountLoginFields: [],
  smsText: '',
  dailyTrialLimit: 0,
  enableValidation: false,
  id: 0,
  collectBirthday: true,
  fbPage: '',
  authFbPost: true,
  collectGender: true,
  collectLocation: true,
  unlimited_speed: false
};

const splashPageAdvancedReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return initialState;
    case SET_VALUE:
      return {...state, [action.payload.name]: action.payload.value};
    case ADD_FIELD:
      return {
        ...state,
        fields: [...state.fields, defaultField(action.payload)]
      };
    case SET_FIELD_VALUE:
      return {...state, fields: replaceFieldValue(state.fields, action.payload)};
    case REORDER_FIELD:
      return {...state, fields: reorder(state.fields, action.payload.sourceIndex, action.payload.targetIndex)};
    case REMOVE_FIELD:
      return {
        ...state,
        fields: state.fields.slice(0, action.payload.index).concat(state.fields.slice(action.payload.index + 1))
      };
    case SET_ACCOUNT_FIELD_VALUE:
      return {
        ...state,
        accountLoginFields: replaceFieldValue(state.accountLoginFields, action.payload)
      };
    default:
      return state;
  }
};

export default splashPageAdvancedReducer;
