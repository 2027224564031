import {
  ADD_FIELD,
  LOAD_ACCOUNT_FIELD_VALUE_SUCCESS,
  LOAD_CONFIG,
  LOAD_CONFIG_FAILURE,
  LOAD_CONFIG_SUCCESS,
  LOAD_EXTRA_CONFIG_SUCCESS,
  REMOVE_FIELD,
  REORDER_FIELD,
  RESET,
  SET_ACCOUNT_FIELD_VALUE,
  SET_FIELD_VALUE,
  SET_VALUE,
} from "../actions/splashPageActions";

function isMandatory(fieldType) {
  return fieldType === "otp";
}
const defaultTitles = {
  email: "Email",
  voucher: "Voucher Code",
  phonenumber: "Phone Number",
  firstname: "First Name",
  lastname: "Last Name",
  gender: "Gender",
  birthday: "Birthday",
  consent: "I like to receive newsletters in my email",
  ukpostcode: "UK Postcode",
  custom_text: "Custom Text",
  custom_number: "Custom Number",
  custom_checkbox: "Text to be shown on the check box",
  custom_select: "Select",
  otp: "OTP",
  country: "Country",
};
const defaultField = (fieldType) => {
  return {
    format: fieldType === "birthday" ? "DD/MM/YYYY" : "",
    mandatory: isMandatory(fieldType),
    default_checked: false,
    female_option_text: fieldType === "gender" ? "Female" : "",
    male_option_text: fieldType === "gender" ? "Male" : "",
    field_type: fieldType,
    regex: "",
    title: defaultTitles[fieldType],
    options_list: [],
  };
};

function replaceFieldValue(fields, { index, name, value }) {
  return fields.map((f, i) =>
    index === i
      ? {
          ...f,
          [name]: value,
        }
      : f
  );
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const initialState = {
  fields: [],
};

const splashPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VALUE:
      return { ...state, [action.payload.name]: action.payload.value };
    case RESET:
      return initialState;
    case LOAD_CONFIG:
      return { ...initialState, loading: true };
    case LOAD_CONFIG_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case LOAD_CONFIG_FAILURE:
      return { ...state, loading: false, error: action.payload.value };
    case LOAD_EXTRA_CONFIG_SUCCESS:
      return { ...state, ...action.payload };
    case ADD_FIELD:
      return {
        ...state,
        fields: state.fields ? [...state.fields, defaultField(action.payload)]: [defaultField(action.payload)],
      };
    case SET_FIELD_VALUE:
      return {
        ...state,
        fields: replaceFieldValue(state.fields, action.payload),
      };
    case REORDER_FIELD:
      return {
        ...state,
        fields: reorder(
          state.fields,
          action.payload.sourceIndex,
          action.payload.targetIndex
        ),
      };
    case REMOVE_FIELD:
      return {
        ...state,
        fields: state.fields
          .slice(0, action.payload.index)
          .concat(state.fields.slice(action.payload.index + 1)),
      };
    case LOAD_ACCOUNT_FIELD_VALUE_SUCCESS:
      return {
        ...state,
        paymentAccountLoginFields: action.payload,
      };
    case SET_ACCOUNT_FIELD_VALUE:
        return {
          ...state,
          paymentAccountLoginFields: replaceFieldValue(state.paymentAccountLoginFields, action.payload)
      };
    default:
      return state;
  }
};
export default splashPageReducer;
