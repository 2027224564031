import {
  LOAD_SITE_SETTINGS,
  LOAD_SITE_SETTINGS_FAILURE,
  LOAD_SITE_SETTINGS_SUCCESS
} from "../actions/siteSettingsActions";

const initialState = {
  isLoading: false,
  error: '',
  settings: {
    permissions: {}
  }
};

const siteSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SITE_SETTINGS:
      return {...initialState, isLoading: true};
    case LOAD_SITE_SETTINGS_SUCCESS:
      return {...state, settings: action.payload.settings, isLoading: false};
    case LOAD_SITE_SETTINGS_FAILURE:
      return {...state, error: action.payload.error, isLoading: false};
    default:
      return state;
  }
};

export default siteSettingsReducer;
