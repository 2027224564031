import React from 'react';

function Logo(props) {
  return (
    <img
      style={{width: props.width || 35}}
      alt="Logo"
      src={props.url}
      {...props}
    />
  );
}

export default Logo;
