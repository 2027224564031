import paymentAccountsService from "../services/paymentAccountsService";
import {errorMessageToString} from "../utils/axios";

export const LOAD_PAYMENT_ACCOUNTS = '@PaymentAccounts/load';
export const LOAD_PAYMENT_ACCOUNTS_SUCCESS = '@PaymentAccounts/load/success';
export const LOAD_PAYMENT_ACCOUNTS_FAILURE = '@PaymentAccounts/load/failure';


export function loadPaymentAccounts(siteId, pageIndex, pageSize, searchString, fromDate, toDate) {
  return (dispatch) => {
    dispatch({type: LOAD_PAYMENT_ACCOUNTS});
    paymentAccountsService.loadPaymentAccounts(siteId, pageIndex, pageSize, searchString, fromDate, toDate)
      .then(data => dispatch({
        type: LOAD_PAYMENT_ACCOUNTS_SUCCESS,
        payload: {
          items: data.items,
          metadata: data.metadata
        }
      }))
      .catch(error => {
        dispatch({type: LOAD_PAYMENT_ACCOUNTS_FAILURE, payload: {error: errorMessageToString(error)}})
      });
  }

}
