import {DELETE_GUEST, DELETE_GUEST_FAILURE, LOAD_GUESTS, LOAD_GUESTS_FAILURE, LOAD_GUESTS_SUCCESS, OPEN_DELETE_CONFIRMATION} from "../actions/guestsActions";
import { openDeleteConfirmation } from 'src/actions/guestsActions';

const initialState = {
  isLoading: false,
  deleteInProgress: false,
  openDeleteConfirmation: false,
  error: '',
  items: [],
  metadata: {}
};
const guestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_GUESTS:
      return {...initialState, isLoading: true, deleteInProgress: false, openDeleteConfirmation: false};
    case LOAD_GUESTS_SUCCESS:
      return {...state, items: action.payload.items, metadata: action.payload.metadata, isLoading: false, deleteInProgress: false, error: ''};
    case OPEN_DELETE_CONFIRMATION:
      return {...state, openDeleteConfirmation: action.payload};
    case DELETE_GUEST:
      return {...state, deleteInProgress: true};
    case LOAD_GUESTS_FAILURE:
    case DELETE_GUEST_FAILURE:
      return {...state, error: action.payload.error, isLoading: false, deleteInProgress: false, openDeleteConfirmation: false};
    default:
      return state;
  }
};
export default guestsReducer;
