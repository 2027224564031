import axios, {mapError} from 'src/utils/axios';

const config = {
  credentials: "same-origin",
  mode: 'cors',
  headers: {
    'Accept': 'application/json'
  }
};

class PackageService {
  getPackages = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymentloginconfig/packages`;
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  getArchivedPackages = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymentloginconfig/packages/archived`;
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  getPackage = (siteId, packageId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymentloginconfig/package/${packageId}`;
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });

  savePackage = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymentloginconfig/packages`;
    axios.post(url, data, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });

  editPackage = (siteId, packageId, data) => new Promise((resolve, reject) => {
    delete data.id;
    const url = `/s/${siteId}/api/v1/paymentloginconfig/package/${packageId}`;
    axios.put(url, data, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });

  reorderPackages = (siteId, packageIds) => new Promise((resolve, reject) =>{
    const url = `/s/${siteId}/api/v1/paymentloginconfig/packages/order`;
    axios.post(url, {display_order: packageIds}, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });

  deletePackage = (siteId, packageId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymentloginconfig/package/${packageId}`;
    axios.delete(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });

}

const packageService = new PackageService();
export default packageService;
