import React, {useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Avatar,
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    SvgIcon,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {ChevronRight, Plus, Search as SearchIcon, XCircle as XIcon} from 'react-feather';
import {useDispatch, useSelector} from "react-redux";
import {updateSite} from "../../../actions/selectedSiteActions";
import {Trans, useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {loadSiteSettings} from "../../../actions/siteSettingsActions";

const useStyles = makeStyles(() => ({
    drawer: {
        width: 500,
        maxWidth: '100%'
    },
}));

function Sites() {
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.account.user);
    const sites = user?.sites;
    const selectedSite = useSelector((state) => state.selectedSite);
    const [value, setValue] = useState('');
    const [isOpen, setOpen] = useState(false);

    const theme = useTheme()
    const handleOpen = () => {
        setOpen(true);

    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleSelectSite = (site) => {
        dispatch(updateSite(site));
        if (site.id > 0) {
            dispatch(loadSiteSettings(site.id));
        }
        setOpen(false);
        history.push('/app/reports/dashboard')
    };

    const handleSearch = (searchString) => {
        setValue(searchString);
    };

    return <>
        <Button
            display="flex"
            variant="outlined"
            size="small"
            color="primary"
            onClick={handleOpen}
            endIcon={<ChevronRight/>}
        >
            <Typography
                variant="h6"
                color="inherit"
            >
                {selectedSite.id === 0 ? <Trans>Go to sites</Trans> : <Trans>Switch Site</Trans>}
            </Typography>
        </Button>
        <Drawer
            anchor="right"
            classes={{paper: classes.drawer}}
            ModalProps={{BackdropProps: {invisible: true}}}
            onClose={handleClose}
            open={isOpen}
            variant="temporary"
            sx={{zIndex: theme.zIndex.modal + 1}}
        >
            <PerfectScrollbar options={{suppressScrollX: true}}>
                <Box p={3}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            variant="h4"
                            color="textPrimary"
                        >
                            <Trans>Select a location to go</Trans>
                        </Typography>
                        <IconButton onClick={handleClose} size="large">
                            <SvgIcon fontSize="small">
                                <XIcon/>
                            </SvgIcon>
                        </IconButton>
                    </Box>
                    <Box mt={2}>
                        <TextField
                            className={classes.queryField}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon
                                            fontSize="small"
                                            color="action"
                                        >
                                            <SearchIcon/>
                                        </SvgIcon>
                                    </InputAdornment>
                                )
                            }}
                            onChange={(event) => handleSearch(event.target.value)}
                            placeholder={t('Search locations')}
                            value={value}
                            variant="outlined"
                        />
                    </Box>
                    <Box mt={4} height="72vh">
                        <PerfectScrollbar options={{suppressScrollX: true}}>
                            <Box mb={2}>
                                <List disablePadding>
                                    {sites?.filter(site => site.name.toLowerCase().indexOf(value.toLowerCase()) > -1)
                                        .map((contact, i) => (
                                            <ListItem button
                                                      selected={contact.id === selectedSite.id}
                                                      divider={i < sites.length - 1}
                                                      key={contact.id}
                                                      onClick={() => handleSelectSite(contact)}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar
                                                        className={classes.avatar}
                                                        size="small"
                                                    >
                                                        {contact.name.substring(0, 1)}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={contact.name}
                                                    primaryTypographyProps={{variant: 'body2'}}
                                                />
                                            </ListItem>
                                        ))}
                                </List>
                            </Box>
                        </PerfectScrollbar>
                    </Box>
                    <Divider/>
                    <Box
                        mt={2}
                        display="flex"
                        justifyContent="flex-start"
                    >
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => history.push('/signup/registration/deployment')}
                            startIcon={<Plus/>}
                        >
                            <Trans>Create New Location</Trans>
                        </Button>
                    </Box>
                </Box>
            </PerfectScrollbar>
        </Drawer>
    </>;
}

export default Sites;
