import axios, { mapError } from "src/utils/axios";

const config = {
  credentials: "same-origin",
  mode: "cors",
  headers: {
    Accept: "application/json",
  },
};

class TwoFactorAuthenticationService {
  checkStatus = () =>
    new Promise((resolve, reject) => {
      const url = "/tf-setup";
      axios
        .get(url, config)
        .then(({data}) => resolve(data))
        .catch((error) => reject((error)));
    });
    enable = (setup, csrf_token) => new Promise((resolve, reject) => {
      const url = '/tf-setup';
      axios
      .post(url,{setup, csrf_token}, config)
      .then(({data}) => resolve(data))
      .catch((error) => reject((error)));
    });
    validate = (code, csrf_token) => new Promise((resolve, reject) => {
      const url = '/tf-validate';
      axios
      .post(url,{code, csrf_token}, config)
      .then(({data}) => resolve(data))
      .catch((error) => reject((error)));
    });
}
export default new TwoFactorAuthenticationService();
