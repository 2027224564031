import Intercom, {IntercomAPI} from 'react-intercom';
import { useSelector } from "react-redux";
import { PERMISSIONS } from 'src/config/permissions';

function Intercomm() {
  const account = useSelector((state) => state.account);
  const {ui_settings} = useSelector(state => state.global);
  if (account.user && account.user.permissions[PERMISSIONS.CHAT_WIDGET] && ui_settings?.INTERCOMM_APP_ID) {
    IntercomAPI('update', {"hide_default_launcher": false});
    const user = {
      email: account.user.email,
      name: account.user.displayname,
      user_hash: account.user.intercom_hash,
      created_at: new Date().getUTCDate(),
    };
    return (<Intercom appID={ui_settings?.INTERCOMM_APP_ID} {...user} />);

  } else {
    IntercomAPI('update', {"hide_default_launcher": true});
    return null;
  }
}

export default Intercomm;
