export const PERMISSIONS = {
    INTEGRATIONS: 'integrations',
    PAYMENT: 'payment',
    PAYMENT_BRANDING: 'payment_branding',
    MACAUTH: 'macauth',
    REPORTS: 'reports',
    SMSGATEWAY: 'smsgateway',
    GUESTUSER: 'guestuser',
    CAMPAIGNS: 'campaigns',
    SPLASH_CAMPAIGNS: 'splash_campaigns',
    EMAIL_CAMPAIGNS: 'email_campaigns',
    LANDINGPAGE: 'landingpage',
    VOUCHER: 'voucher',
    MULTI_SITE_VOUCHER: 'multisitevoucher',
    CLIENT_ACCESS: 'client_access',
    GUESTS:'guests',
    ROUTERSETINGS: 'routersettings',
    SITESETTINGS:'sitesettings',
    ADMINS: 'control_panel',
    CLIENTS: 'clients',
    BRANDING: 'branding',
    CONTROL_PANEL: 'control_panel',
    ALLOW_ALL: 'all',
    CLICK_THROUGH_LOGIN: "clickthroughlogin",
    EMAIL: "email",
    PASSWORD_LOGIN: "passwordlogin",
    FACEBOOK: "facebook",
    PHONE: "phone",
    API_KEY: "api",
    CHAT_WIDGET: "chat_widget"
}