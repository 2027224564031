import {
  LOAD_PAYMENT_ACCOUNTS,
  LOAD_PAYMENT_ACCOUNTS_FAILURE,
  LOAD_PAYMENT_ACCOUNTS_SUCCESS
} from "../actions/paymentAccountActions";

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  metadata: {}
};
const paymentAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PAYMENT_ACCOUNTS:
      return {...initialState, isLoading: true};
    case LOAD_PAYMENT_ACCOUNTS_SUCCESS:
      return {...state, items: action.payload.items, metadata: action.payload.metadata, isLoading: false, error: ''};
    case LOAD_PAYMENT_ACCOUNTS_FAILURE:
      return {...state, error: action.payload.error, isLoading: false};
    default:
      return state;
  }
};
export default paymentAccountReducer;
