import authService from 'src/services/authService';
import {setSelectedSite} from "./selectedSiteActions";
import {loadMetaData} from "./globalActions";
import tfaService from 'src/services/tfaService';
import { posthog } from 'posthog-js';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const LOGIN_2FA = '@account/login-2fa';
export const SILENT_LOGIN = '@account/silent-login';
export const SET_SITE_CREATION_NEEDED = '@account/set-site-creation-needed';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';
export const ADD_SITE = '@account/add-site';

export function login(email, password) {
  return async (dispatch) => {
    try {
      dispatch({type: LOGIN_REQUEST});

      const user = await authService.loginWithEmailAndPassword(email, password);
      await dispatch({type: 'CLEAR_STATE'});
      await dispatch(loadMetaData());
      await dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
      return await dispatch(setSelectedSite(user));
    } catch (error) {
      if(error.reason === '2faRequired'){
        dispatch({type: LOGIN_2FA, payload: {token: error.token}})
      }else{
        dispatch({type: LOGIN_FAILURE});
        throw error;
      }
    }
  };
}
export function loginWith2FA(code){
  return async (dispatch, getState) => {
    try{
      const response  = await tfaService.validate(code, getState().account.token);
      const {user, csrf_token} = response?.response;
      authService.setSession(csrf_token);
      await dispatch({type: 'CLEAR_STATE'});
      await dispatch(loadMetaData());
      await dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            user
          }
        });
      return await dispatch(setSelectedSite(user));

    } catch(error){
      dispatch({type: LOGIN_FAILURE});
      throw error;
    }
  }
}


export function register(data, onSuccess, onError) {
  return async (dispatch) => {
    try {
      dispatch({type: LOGIN_REQUEST});

      const user = await authService.register(data);
      await dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
      posthog.identify(user.email);
      posthog.capture('user signed up');
      onSuccess();
    }catch (error) {
      dispatch({type: LOGIN_FAILURE});
      console.log(error);
      onError(error);
    }

  }
}

export function setUserData(user) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      user
    }
  });
}

export function setSiteCreationNeeded(value){
  return dispatch => dispatch({
    type: SET_SITE_CREATION_NEEDED,
    payload: {
      value
    }
  })
}

export function addSite(site) {
  return ({
    type: ADD_SITE,
    payload: {
      site
    }
  })

}

export function logout() {
  return async (dispatch) => {
    await authService.logout();
    posthog.reset()
    dispatch({
      type: LOGOUT
    });
  };
}

export function updateProfile(update) {
  return (dispatch) => {
    authService.updateProfile(update).then((response) => dispatch({
      type: UPDATE_PROFILE,
      payload: {user: response.data}
    }));
  };
}
