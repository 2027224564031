import { Box, ButtonBase, Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Person as PersonIcon } from "@mui/icons-material";
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { IntercomAPI } from 'react-intercom';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logout } from 'src/actions/accountActions';
import { loadMetaData } from "../../../actions/globalActions";

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 32,
        width: 32,
        marginRight: theme.spacing(1)
    },
    popover: {
        width: 200
    }
}));

function Account() {
    const classes = useStyles();
    const history = useHistory();
    const ref = useRef(null);
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const {t} = useTranslation();
    useEffect(()=>{
        dispatch(loadMetaData());
    },[dispatch]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = async () => {
        IntercomAPI('update', {"hide_default_launcher": true});

        try {
            handleClose();
            await dispatch(logout());
            history.push('/');
        } catch (error) {
            toast.error(t('Unable to logout'));
        }
    };

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                component={ButtonBase}
                onClick={handleOpen}
                ref={ref}
            >
                <PersonIcon color="primary"/>
            </Box>
            <Menu
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                keepMounted
                PaperProps={{className: classes.popover}}
                anchorEl={ref.current}
                open={isOpen}
            >
                <MenuItem
                    component={RouterLink} onClick={handleClose}
                    to="/app/account"
                >
                    <Trans>Account</Trans>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <Trans>Logout</Trans>
                </MenuItem>
            </Menu>
        </>
    );
}

export default Account;
