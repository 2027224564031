import siteService from "../services/siteService";

export const LOAD_SITE_SETTINGS = '@SiteSettings/load';
export const LOAD_SITE_SETTINGS_SUCCESS = '@SiteSettings/load-success';
export const LOAD_SITE_SETTINGS_FAILURE = '@SiteSettings/load-failure';

export function loadSiteSettings(siteId) {
  return (dispatch) => {
    dispatch(loadSiteSettingsStart());
    siteService.getSiteDetails(siteId)
      .then(data => dispatch(loadSiteSettingsSuccess(data)))
      .catch(error => dispatch(loadSiteSettingsFailure(error)));
  }
}

export function loadSiteSettingsStart() {
  return {
    type: LOAD_SITE_SETTINGS
  }
}

export function loadSiteSettingsSuccess(settings) {
  return {
    type: LOAD_SITE_SETTINGS_SUCCESS,
    payload: {
      settings
    }
  }
}

export function loadSiteSettingsFailure(error) {
  return {
    type: LOAD_SITE_SETTINGS_FAILURE,
    payload: {
      error
    }
  }
}
