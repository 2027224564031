import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { useDispatch } = require("react-redux");
const { logout } = require("src/actions/accountActions");

const Logout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    async function triggerLogout() {
      dispatch(logout());
      history.push('/login');
    }
    triggerLogout();
  },[]);
  return null;
}
export default Logout;