import {LOAD_TRANSACTIONS, LOAD_TRANSACTIONS_FAILURE, LOAD_TRANSACTIONS_SUCCESS} from "../actions/transactionActions";

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  metadata: {}
};
const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRANSACTIONS:
      return {...initialState, isLoading: true};
    case LOAD_TRANSACTIONS_SUCCESS:
      return {...state, items: action.payload.items, metadata: action.payload.metadata, isLoading: false, error: ''};
    case LOAD_TRANSACTIONS_FAILURE:
      return {...state, error: action.payload.error, isLoading: false};
    default:
      return state;
  }
};
export default transactionsReducer
