import { Button } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { loadSubscription, updateCurrentPlan } from "../actions/subscriptionActions";
import subscriptionService from "../services/subscriptionService";
import { errorMessageToString } from "src/utils/axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


function UpgradeAction() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {currentPlan} = useSelector(state => state.subscription);
  const {stripe_pk} = useSelector(state => state.global);
  const history = useHistory();
  const stripePromise = loadStripe(stripe_pk);
  const [inProgress, setInProgress] = useState(false);
  const [open, setOpen] = useState(false);
  const isTrial = currentPlan.status === 'trialing';

  function handleClose() {
    setOpen(false);
  }

  function completePurchase(token) {
    history.push('/change-subscription/buy_now')
  }

  return <><Button variant="contained" color="primary" onClick={async () => {
    completePurchase()
  }}>{isTrial ? t('Upgrade Now') : t('Buy Now')}</Button>
  </>;
}

export default UpgradeAction;
