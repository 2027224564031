import {combineReducers} from 'redux';
import accountReducer from './accountReducer';
import selectedSiteReducer from "./selectedSiteReducer";
import campaignReducer from "./campaignReducer";
import globalReducer from "./globalReducer";
import subscriptionReducer from "./subscriptionReducer";
import splashPageReducer from "./splashPageReducer";
import siteSettingsReducer from "./siteSettingsReducer";
import splashPageAdvancedReducer from "./splashPageAdvancedReducer";
import packagesReducer from "./packagesReducer";
import voucherReducer from "./voucherReducer";
import multiSiteVoucherReducer from "./multiSiteVoucherReducer";
import storage from 'redux-persist/lib/storage';
import guestUserAccountsReducer from "./guestUserAccountsReducer";
import transactionsReducer from "./transactionsReducer";
import paymentAccountReducer from "./paymentAccountReducer";
import guestsReducer from "./guestsReducer";
import whitelistReducer from "./whitelistReducer";
import splashAdsReducer from "./splashAdsReducer";
import registrationReducer from './registrationReducer';
import dashboardReducer from './dashboardReducer';

const appReducer = combineReducers({
    account: accountReducer,
    selectedSite: selectedSiteReducer,
    campaign: campaignReducer,
    global: globalReducer,
    subscription: subscriptionReducer,
    splashPage: splashPageReducer,
    splashPageAdvanced: splashPageAdvancedReducer,
    siteSettings: siteSettingsReducer,
    packages: packagesReducer,
    vouchers: voucherReducer,
    multiSiteVouchers: multiSiteVoucherReducer,
    guestUserAccounts: guestUserAccountsReducer,
    transactions: transactionsReducer,
    paymentAccounts: paymentAccountReducer,
    guests: guestsReducer,
    whitelist: whitelistReducer,
    splashAd: splashAdsReducer,
    registration: registrationReducer,
    dashboard: dashboardReducer,
});
const rootReducer = (state, action) => {
    if (action.type === 'CLEAR_STATE') {
        // for all keys defined in your persistConfig(s)
        storage.removeItem('persist:root');

        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
