export const REGISTRATION_SET_VALUE = '@registration/set-value';
export const REGISTRATION_INIT = '@registration/init';

export function setValue(name, value){
  return ({
    type: REGISTRATION_SET_VALUE,
    payload: {name,value}
  })
}
export function initRegistration() {
  return ({
    type: REGISTRATION_INIT
  })
}
