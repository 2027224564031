import axios, {mapError} from 'src/utils/axios';

const config = {
  credentials: 'same-origin',
  mode: 'cors',
  headers: {
    'Accept': 'application/json'
  }
};

class AtosService {
  getAtosPageStyles = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/atos/splashpage`;
    axios.get(url, config)
      .then(({data}) => resolve(data))
      .catch(error => reject(mapError(error)));
  });
  getAtosFields = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/atos/loginfields`;
    axios.get(url, config)
      .then(({data}) => resolve(data))
      .catch(error => reject(mapError(error)));
  });
  saveAtosFields = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/atos/loginfields`;
    axios.put(url, data, config)
      .then(({data}) => resolve(data))
      .catch(error => reject(mapError(error)));
  })
  updateAtosPageStyles = (siteId, data)=> new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/atos/splashpage`;
    axios.put(url, data, config)
      .then(({data}) => resolve(data))
      .catch(error => reject(mapError(error)));
  });
  getAtosPageContents = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/atos/`;
    axios.get(url, config)
      .then(({data}) => resolve(data))
      .catch(error => reject(mapError(error)));
  });
  updateAtosPageContents = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/atos/`;
    delete data.id;
    axios.put(url, data, config)
      .then(({data}) => resolve(data))
      .catch(error => reject(mapError(error)));
  });
}

const atosService = new AtosService();
export default atosService;
