import guestService from "../services/guestService";
import {errorMessageToString} from "../utils/axios";

export const LOAD_GUESTS = '@Guests/load';
export const LOAD_GUESTS_SUCCESS = '@Guests/load/success';
export const LOAD_GUESTS_FAILURE = '@Guests/load/failure';
export const OPEN_DELETE_CONFIRMATION = '@Guests/open/confirmation'
export const DELETE_GUEST = '@Guest/delete';
export const DELETE_GUEST_FAILURE = '@Guest/delete/failure';

export function loadGuests(siteId, pageIndex, pageSize, searchString, fromDate, toDate) {
  return (dispatch) => {
    dispatch({type: LOAD_GUESTS});
    guestService.loadAllGuests(pageIndex, pageSize, searchString, siteId, fromDate, toDate)
      .then(data => dispatch({
        type: LOAD_GUESTS_SUCCESS,
        payload: {
          items: data.items,
          metadata: data.metadata
        }
      }))
      .catch(error => {
        dispatch({type: LOAD_GUESTS_FAILURE, payload: {error: errorMessageToString(error)}})
      });
  }
}
export function openDeleteConfirmation(value) {
  return {type: OPEN_DELETE_CONFIRMATION, payload: value};
}
export function deleteGuest(id, search, fromDate, toDate) {
  return (dispatch, getState) => {
    const {guests, selectedSite} = getState();
    dispatch({type: DELETE_GUEST});
    guestService.deleteGuest(selectedSite.id, id)
      .then(_ => dispatch(loadGuests(selectedSite.id,1, guests.metadata.per_page, search,fromDate, toDate )))
      .catch(error => {
        console.log(error.statusText);
        dispatch({type: DELETE_GUEST_FAILURE, payload: {error: errorMessageToString(error)}})
      });
  };
}
