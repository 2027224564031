/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Hidden, List, ListSubheader, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Bold, List as ListIcon, Package, PieChart as PieChartIcon, Settings } from 'react-feather';
import { ChromeReaderMode, ContactMail, Devices, Email, People, AdsClick } from "@mui/icons-material";
import NavItem from './NavItem';
import { useSelector } from "react-redux";
import GuestsIcon from "../../../components/GuestsIcon";
import AdminIcon from "../../../components/AdminIcon";
import { PERMISSIONS } from "../../../config/permissions";

const navConfig = [
    {
        inSiteOnly: true,
        permission: PERMISSIONS.ALLOW_ALL,
        items: [
            {
                title: 'Dashboard',
                icon: PieChartIcon,
                href: '/app/reports/dashboard',
                exact: true
            },
        ]
    }, {
        inSiteOnly: false,
        dashboardOnly: true,
        permission: PERMISSIONS.ADMINS,
        items: [
            {
                title: 'Admins',
                icon: AdminIcon,
                href: '/app/admins',
                exact: false
            },
        ]
    }, {
        inSiteOnly: false,
        dashboardOnly: true,
        permission: PERMISSIONS.CLIENTS,
        items: [
            {
                title: 'Clients',
                icon: People,
                href: '/app/clients',
                exact: false
            },
        ]
    }, {
        inSiteOnly: false,
        dashboardOnly: true,
        permission: PERMISSIONS.CONTROL_PANEL,
        items: [
            {
                title: 'Branding',
                icon: Bold,
                href: '/app/branding',
                exact: false
            },
        ]
    }, {
        inSiteOnly: true,
        permission: PERMISSIONS.LANDINGPAGE,
        items: [
            {
                title: ' Splash Page',
                icon: Devices,
                href: '/app/site/splash-pages',
                exact: false
            },
        ]
    }, {
        inSiteOnly: true,
        subheader: 'Campaigns',
        permission: [PERMISSIONS.EMAIL_CAMPAIGNS, PERMISSIONS.SPLASH_CAMPAIGNS],
        items: [
            {
                title: 'Email Campaigns',
                icon: Email,
                href: '/app/campaigns/email',
                exact: false,
                permission: PERMISSIONS.EMAIL_CAMPAIGNS,
            }, {
                title: 'Splash page Ads',
                icon: AdsClick,
                href: '/app/campaigns/splash-page-ad',
                exact: false,
                permission: PERMISSIONS.SPLASH_CAMPAIGNS,
            },
        ]
    }, {
        inSiteOnly: true,
        permission: PERMISSIONS.GUESTS,
        items: [
            {
                title: 'Guests',
                icon: GuestsIcon,
                href: '/app/guests',
                exact: false
            },
        ]
    },
    {
        inSiteOnly: true,
        permission: PERMISSIONS.MULTI_SITE_VOUCHER,
        items: [
            {
                title: 'Multi-Site Vouchers',
                icon: ChromeReaderMode,
                href: '/app/voucher/multisite/manage',
                exact: false
            },
        ]
    },{
        inSiteOnly: true,
        permission: PERMISSIONS.VOUCHER,
        items: [
            {
                title: 'Vouchers',
                icon: ChromeReaderMode,
                href: '/app/voucher/manage',
                exact: false
            },
        ]
    }, {
        inSiteOnly: true,
        subheader: 'Payments',
        permission: PERMISSIONS.PAYMENT,
        items: [
            {
                title: 'Packages',
                icon: Package,
                href: '/app/payment/packages',
                exact: false
            },
            {
                title: 'Transactions',
                icon: ListIcon,
                href: '/app/payment/transactions',
                exact: false
            }, {
                title: 'Accounts',
                icon: People,
                href: '/app/payment/accounts',
                exact: false
            },
        ]
    }, {
        inSiteOnly: true,
        permission: PERMISSIONS.GUESTUSER,
        items: [
            {
                title: 'Guest Accounts',
                icon: ContactMail,
                href: '/app/guestuser/manage',
                exact: false
            },
        ]
    }, {
        inSiteOnly: true,
        permission: PERMISSIONS.SITESETTINGS,
        items: [
            {
                title: 'Settings',
                icon: Settings,
                href: '/app/settings-view',
                exact: false
            },
        ]
    },
];

function renderNavItems({ items, permissions, ...rest }) {
    return (
        <List disablePadding>
            {items
            .filter( item => !item.permission || permissions[item.permission])
            .reduce(
                (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
                []
            )}
        </List>
    );
}

function reduceChildRoutes({
    acc,
    pathname,
    item,
    depth = 0
}) {
    const key = item.title + depth;

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                key={key}
                info={item.info}
                href={item.href}
                open={Boolean(open)}
                title={item.title}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                key={key}
                info={item.info}
                title={item.title}
            />
        );
    }

    return acc;
}

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    }
}));

function NavBar({ openMobile, onMobileClose, isSplashpage }) {
    const classes = useStyles();
    const location = useLocation();
    const selectedSite = useSelector(state => state.selectedSite);
    const siteSettings = useSelector(state => state.siteSettings);
    const { user } = useSelector(state => state.account);
    const permissions = { [PERMISSIONS.ALLOW_ALL]: true, ...siteSettings?.settings?.permissions, ...user?.permissions };
    let navs = navConfig;

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Box p={2}>
                    <Box
                        mt={2}
                        textAlign="left"
                    >
                        <Typography
                            variant="h5"
                            color="inherit"
                        >
                            {selectedSite.name}
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <Box p={2}>
                    {navs.filter(config => (selectedSite.id !== 0 && !config.dashboardOnly) || (selectedSite.id === 0 && !config.inSiteOnly)).map((config, index) => {
                        return ((Array.isArray(config.permission) && config.permission.filter(p => permissions[p]).length > 0) || permissions[config.permission] ?
                            <Box key={config.items[0].title}>
                                {config.subheader && <Divider />}
                                <List
                                    key={index}
                                    subheader={(
                                        <ListSubheader
                                            disableGutters
                                            disableSticky
                                        >
                                            {config.subheader}
                                        </ListSubheader>
                                    )}
                                >
                                    {renderNavItems({ items: config.items,permissions, pathname: location.pathname })}
                                    {config.subheader && <Divider />}
                                </List></Box> : null);
                    })}
                </Box>
                <Divider />
            </PerfectScrollbar>
        </Box>
    );
    if(isSplashpage){
        return <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
    >
        {content}
    </Drawer>
    }

    return <>
        <Hidden lgUp>
            <Drawer
                anchor="left"
                classes={{ paper: classes.mobileDrawer }}
                onClose={onMobileClose}
                open={openMobile}
                variant="temporary"
            >
                {content}
            </Drawer>
        </Hidden>
        <Hidden lgDown>
            <Drawer
                anchor="left"
                classes={{ paper: classes.desktopDrawer }}
                open
                variant="persistent"
            >
                {content}
            </Drawer>
        </Hidden>
    </>;
}

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default NavBar;
