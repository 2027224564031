import axios, {mapError} from 'src/utils/axios';

const config = {
  credentials: "same-origin",
  mode: 'cors',
  headers: {
    'Accept': 'application/json'
  }
};

class MultiSiteVoucherService {
  loadUnusedVouchers = (pageIndex, pageSize, searchString, siteId, sortOn, sortBy) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/multisite_voucher/unused`;
    const params = {
      page: pageIndex,
      per_page: pageSize,
      search: searchString,
      sort_by: sortBy,
      sort_on: sortOn
    };
    axios.get(url, {...config, params}).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  loadUsedVouchers = (pageIndex, pageSize, searchString, siteId, sortOn, sortBy) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/multisite_voucher/inuse`;
    const params = {
      page: pageIndex,
      per_page: pageSize,
      search: searchString,
      sort_by: sortBy,
      sort_on: sortOn
    };
    axios.get(url, {...config, params}).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  loadExpiredVouchers = (pageIndex, pageSize, searchString, siteId, sortOn, sortBy) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/multisite_voucher/expired`;
    const params = {
      page: pageIndex,
      per_page: pageSize,
      search: searchString,
      sort_by: sortBy,
      sort_on: sortOn
    };
    axios.get(url, {...config, params}).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  loadBatchActionUrls = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/multisite_voucher/batches`;
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });

  deleteVouchers = (url) => new Promise((resolve, reject) => {
    axios.delete(url, config)
      .then(({data}) => resolve(data))
      .catch(error => reject(mapError(error)));
  });
  deleteVoucher = (siteId, id) => new Promise((resolve, reject) => {
    axios.delete(`/s/${siteId}/api/v1/multisite_voucher/${id}`, config)
      .then(({data}) => resolve(data))
      .catch(error => reject(mapError(error)));
  });
  getVoucherDesign = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/multisite_voucherdesigner/`;
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  saveVoucherDesign = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/multisite_voucherdesigner/`;
    axios.put(url, data, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  createVoucher = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/multisite_voucher/`;
    axios.post(url, data, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  getVoucherDetails = (siteId, voucherId) => new Promise((resolve, reject)=> {
    const url = `/s/${siteId}/api/v1/multisite_voucher/${voucherId}`;
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });

  disconnectUser = (siteId, voucherId, authId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/multisite_voucher/${voucherId}/disconnect/${authId}`;
    axios.post(url, {}, config)
        .then(({data}) => resolve(data))
        .catch(error => reject(mapError(error)));
  });

  uploadImage = (siteId, file) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/multisite_voucherdesigner/logo`;
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      credentials: "same-origin",
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'content-type': 'multipart/form-data'
      }
    };
    axios.post(url, formData, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));

  })
}

const multiSiteVoucherService = new MultiSiteVoucherService();
export default multiSiteVoucherService;
