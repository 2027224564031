import paymentTransactionsService from "../services/paymentTransactionsService";
import {errorMessageToString} from "../utils/axios";

export const LOAD_TRANSACTIONS = '@Transactions/load';
export const LOAD_TRANSACTIONS_SUCCESS = '@Transactions/load/success';
export const LOAD_TRANSACTIONS_FAILURE = '@Transactions/load/failure';

export function loadTransactions(siteId, pageIndex, pageSize, searchString, fromDate, toDate) {
  return (dispatch) => {
    dispatch({type: LOAD_TRANSACTIONS});
    paymentTransactionsService.loadTransactions(siteId, pageIndex, pageSize, searchString, fromDate, toDate)
      .then(data => dispatch({
        type: LOAD_TRANSACTIONS_SUCCESS,
        payload: {
          items: data.items,
          metadata: data.metadata
        }
      }))
      .catch(error => {
        dispatch({type: LOAD_TRANSACTIONS_FAILURE, payload: {error: errorMessageToString(error)}})
      });
  }

}
