import {
  LOAD_ALL,
  UPDATE_BILLING_CARD,
  UPDATE_BILLING_DETAILS,
  UPDATE_CURRENT_PLAN
} from "../actions/subscriptionActions";

const initialState = {
  billingAddress: {
    name: '',
    line1: '',
    line2: '',
    city: '',
    country: '',
    zipcode: '',
    id: 0,
    pricing: {
      collect: {monthly: 0, yearly: 0, currency: ''},
      engage: {monthly: 0, yearly: 0, currency: ''},
      provider: {monthly: 0, yearly: 0, currency: ''}
    },
  },
  currentPlan: {
    plan_type: '',
    status: '',
    trial_end: '',
    current_period_end: '',
    price: ''
  },
  billingHistory: [{
    period_start: '',
    period_end: '',
    amount: '',
    invoice_pdf: ''
  }],
  card: {
    card_details: {
      last4: '',
      brand: '',
      exp_month: '',
      exp_year: ''
    }
  }
};
const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ALL:
      return {...action.payload};
    case UPDATE_BILLING_DETAILS:
      return {...state, billingAddress: action.payload.billingAddress};
    case UPDATE_BILLING_CARD:
      return {...state, card: action.payload.card};
    case UPDATE_CURRENT_PLAN:
      return {...state, currentPlan: action.payload.plan};
    default:
      return state;
  }
};
export default subscriptionReducer;
