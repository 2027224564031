import guestUserService from "../services/guestUserService";
import {errorMessageToString} from "../utils/axios";

export const LOAD_USER_ACCOUNTS = '@GuestUsers/load';
export const LOAD_USER_ACCOUNTS_SUCCESS = '@GuestUsers/load/success';
export const LOAD_USER_ACCOUNTS_FAILURE = '@GuestUsers/load/failure';
export const DELETE_USER_ACCOUNTS = '@GuestUsers/delete';
export const DELETE_USER_ACCOUNTS_FAILURE = '@GuestUsers/delete/failure';

export function loadGuestUserAccounts(pageIndex, pageSize, searchString, siteId) {
  return (dispatch) => {
    dispatch({type: LOAD_USER_ACCOUNTS});
    guestUserService.loadGuestUserAccounts(pageIndex, pageSize, searchString, siteId)
      .then(data => {
        dispatch({
          type: LOAD_USER_ACCOUNTS_SUCCESS,
          payload: {
            items: data.items,
            metadata: data.metadata
          }
        });
      }).catch(error => {
      dispatch({type: LOAD_USER_ACCOUNTS_FAILURE, payload: {error: errorMessageToString(error)}})
    })
  }
}
export function deleteUser(id) {
  return (dispatch, getState) => {
    const {vouchers, selectedSite} = getState();
    dispatch({type: DELETE_USER_ACCOUNTS});
    guestUserService.deleteGuestUserAccountById(selectedSite.id, id)
      .then(_ => dispatch(loadGuestUserAccounts(1, vouchers.metadata.per_page, '', selectedSite.id)))
      .catch(error => {
        console.log(error.statusText);
        dispatch({type: DELETE_USER_ACCOUNTS_FAILURE, payload: {error: errorMessageToString(error)}})
      });

  };
}
