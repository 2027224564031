import axios from 'axios';
import * as _ from 'lodash';

const instance = axios.create();

export default instance;

export function mapError(error) {
  if (_.isObject(error?.response?.data)) {
    return {
      status: error.response.status,
      schema_errors: error.response.data.schema_errors || [],
      message: error.response.data.message || ''
    }
  } else {
    return {
      status: error.response.status,
      schema_errors: [],
      message: 'Unknown error'
    }
  }
}

export function errorMessageToString(error) {
  console.log(objectToString(error.schema_errors));
  let errors = '' + (error.message || '');
  _.entries(error.schema_errors).forEach(e => {
    errors = errors + e[0] + ' : ' + (_.isString(e[1])? e[1]: objectToString(e[1])) + '  ';
  });
  return errors;
}

function objectToString(obj){
  let errors = '';
  _.entries(obj).forEach(e => {
    if(_.isString(e[1])){
      errors = errors + e[1] + '  ';
    }else{
      errors  += objectToString(e[1]);
    }
  });
  return errors;
}

export function createFormikErrorObject(error){
  let errorObject = {};
  _.entries(error.schema_errors).forEach(e => {
    errorObject = {...errorObject, [e[0]]: e[1][0]}
  });
  return {...errorObject, submit: error.message};
}
export function createLoginFormikErrorObject(error){
  const e = error.data.response;
  let errorObject = {};
  _.entries(e.field_errors).forEach(e => {
    errorObject = {...errorObject, [e[0]]: e[1][0]}
  });
  return {...errorObject, submit: e.message};
}