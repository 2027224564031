import axios, {mapError} from 'src/utils/axios';
import ImageUpload from '../views/pages/SiteCreateView/ImageUpload';

const config = {
  credentials: "same-origin",
  mode: 'cors',
  headers: {
    'Accept': 'application/json'
  }
};

class SiteService {
  getSiteDetails = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/api/v1/site/${siteId}`;
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  updateSiteDetails = (siteId, site) => new Promise((resolve, reject) => {
    const url = `/s/api/v1/site/${siteId}`;
    axios.put(url, site, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  createSite = (site) => new Promise((resolve, reject) => {
    const url = `/s/api/v1/site/`;
    axios.post(url, site, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  getSupportedRouters = () => new Promise((resolve, reject) => {
    const url = '/a/api/v1/meta/';
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  getRedirectUrl = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/api/v1/site/${siteId}/redirecturl`;
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  updateRedirectUrl = (siteId, redirect_url) => new Promise((resolve, reject) => {
    const url = `/s/api/v1/site/${siteId}/redirecturl`;
    axios.put(url, {redirect_url}, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  updateSiteInfo = (siteId, name, currency, default_countrycode, timezone, validate_email) => new Promise((resolve, reject)=> {
    const url = `/s/api/v1/site/${siteId}/general`;
    axios.put(url, {name, currency, default_countrycode, timezone, validate_email}, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  getSiteInfo = (siteId) => new Promise((resolve,reject )=> {
    const url = `/s/api/v1/site/${siteId}/general`;
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  getSiteGuestFields = (siteId) => new Promise((resolve,reject )=> {
    const url = `/s/api/v1/site/${siteId}/guestfields`;
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  deleteSite = (siteId) => new Promise((resolve, reject) => {
    const url = `/s/api/v1/site/${siteId}`;
    axios.delete(url)
        .then(({data}) => {
          resolve(data);
        }).catch(error => reject(mapError(error)));

  })
  uploadImage = (file) => new Promise((resolve, reject) => {
    const url = `/s/api/v1/site/landingfile`;
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      credentials: "same-origin",
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'content-type': 'multipart/form-data'
      }
    };
    axios.post(url, formData, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));

  });
}

const siteService = new SiteService();
export default siteService;
