import axios, {mapError} from 'src/utils/axios';

const config = {
  credentials: "same-origin",
  mode: 'cors',
  headers: {
    'Accept': 'application/json'
  }
};

class PaymentAccountsService {
  loadPaymentAccounts = (siteId, pageIndex, pageSize, searchString, fromDate, toDate) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymentaccount/`;
    const params = {
      page: pageIndex,
      per_page: pageSize,
      search: searchString,
      fromdate: fromDate,
      todate: toDate
    };
    axios.get(url, {...config, params}).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  getPaymentAccountDetails = (siteId, paymentAccountId) => new Promise((resolve,reject) =>{
    const url = `/s/${siteId}/api/v1/paymentaccount/${paymentAccountId}`;
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  })
  updateAccount = (siteId, accountId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymentaccount/${accountId}`;
    axios.put(url, data, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  resetPassword = (siteId, paymentAccountId, password) => new Promise((resolve, reject) =>{
    const url = `/s/${siteId}/api/v1/paymentaccount/${paymentAccountId}/password`;
    axios.post(url, {password}, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  })
  disconnectDevice = (siteId, paymentAccountId, authId) => new Promise((resolve, reject)=> {
    const url = `/s/${siteId}/api/v1/paymentaccount/${paymentAccountId}/disconnect/${authId}`;
    axios.post(url, {}, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  stopRecurringPayments = (siteId, paymentAccountId, recurringPaymentId) => new Promise((resolve, reject) => {
    const url =`/s/${siteId}/api/v1/paymentaccount/${paymentAccountId}/stoprecurring/${recurringPaymentId}`;
    axios.post(url, {}, config).then(({data}) =>{
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  startRecurringPayments = (siteId, paymentAccountId, recurringPaymentId) => new Promise((resolve, reject) => {
    const url =`/s/${siteId}/api/v1/paymentaccount/${paymentAccountId}/startrecurring/${recurringPaymentId}`;
    axios.post(url, {}, config).then(({data}) =>{
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  addDevice = (siteId, paymentAccountId, mac) => new Promise((resolve, reject) =>{
    const url = `/s/${siteId}/api/v1/paymentaccount/${paymentAccountId}/connect`;
    axios.post(url, {guestmac: mac}, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  resendEmail = (siteId, paymentAccountId) => new Promise((resolve, reject)=> {
    const url = `/s/${siteId}/api/v1/paymentaccount/${paymentAccountId}/send/email`;
    axios.post(url, {}, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  resendSMS = (siteId, paymentAccountId) => new Promise((resolve, reject)=> {
    const url = `/s/${siteId}/api/v1/paymentaccount/${paymentAccountId}/send/sms`;
    axios.post(url, {}, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  getStripeSecret = () => new Promise((resolve, reject) => {
    const url = '/a/api/v1/billingcard/setup_intent';
    axios.get(url).then(({data}) => resolve(data))
    .catch(error => reject(mapError(error)));
  })
  getBillingCardInfo = () => new Promise((resolve, reject) => {
    const url = '/a/api/v1/billingcard/';
    axios.get(url).then(({data}) => resolve(data))
    .catch(error => reject(mapError(error)));
  })
  getBillingDetails = () => new Promise((resolve, reject) => {
    const url = '/a/api/v1/billingdetails/';
    axios.get(url).then(({data}) => resolve(data))
    .catch(error => reject(mapError(error)));
  })
  updateBillingDetails = (payload) => new Promise((resolve, reject) => {
    const url = '/a/api/v1/billingdetails/';
    axios.post(url, payload).then(({data}) => resolve(data))
    .catch(error => reject(mapError(error)));
  })
  getInvoices = () => new Promise((resolve, reject) => {
    const url = '/a/api/v1/invoices/';
    axios.get(url).then(({data}) => resolve(data))
    .catch(error => reject(mapError(error)));
  })
  updateNextPaymentDate = (siteId, paymentAccountId, reccurringPaymentId, next_payment_on) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymentaccount/${paymentAccountId}/changerecurring/${reccurringPaymentId}`;
    axios.post(url, {next_payment_on}).then(({data}) => resolve(data))
    .catch(error => reject(mapError(error)));
  })

}

const paymentAccountsService = new PaymentAccountsService();
export default paymentAccountsService;
