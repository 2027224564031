import moment from 'moment';
import axios, {mapError} from 'src/utils/axios';

const config = {
  credentials: "same-origin",
  mode: 'cors',
  headers: {
    'Accept': 'application/json'
  }
};

class GuestService {
  loadAllGuests = (pageIndex, pageSize, searchString, siteId, fromDate, toDate) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guest/`;
    const params = {
      page: pageIndex,
      per_page: pageSize,
      search: searchString,
      fromdate: fromDate,
      todate: toDate
    };
    axios.get(url, {...config, params}).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  loadGuest = (siteId,guestId) => new Promise((resolve,reject) => {
    const url = `/s/${siteId}/api/v1/guest/${guestId}`;
    axios.get(url).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  })
  disconnectDevice = (siteId, guestId, authId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guest/${guestId}/disconnect/${authId}`;
    axios.post(url, {}, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  deleteGuest = (siteId, guestId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guest/${guestId}`;
    axios.delete(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  download = (resource, siteId, search, fromDate, toDate) => new Promise((resolve, reject) => {
    function formatDateParam(date, field) {
      return date ? `&${field}=${moment(date).format('DD-MM-YYYY')}`: '';
    }
    const url = `/s/${siteId}/api/v1/guest/download/${resource}`
    return axios.get(`${url}?search=${search}${formatDateParam(fromDate, 'fromdate')}${formatDateParam(toDate, 'todate')}`)
    .then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  })
  downloadGuests = (siteId, search, fromDate, toDate) => {
    return this.download('guests',  siteId, search, fromDate, toDate);
  }
  downloadSessions = (siteId, search, fromDate, toDate) => {
    return this.download('sessions',  siteId, search, fromDate, toDate);
  }
  getDownloadStatus= (url) => new Promise((resolve, reject) => {
    axios.get(url)
    .then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  })

}

const guestService = new GuestService();
export default guestService;
