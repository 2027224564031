import jwtDecode from 'jwt-decode';
import storage from 'redux-persist/lib/storage';
import axios, {mapError} from 'src/utils/axios';
import Cookies from 'js-cookie';
import * as _ from 'lodash';
import * as Sentry from '@sentry/react';

const config = {
  credentials: "same-origin",
  mode: 'cors',
  headers: {
    'Accept': 'application/json'
  }
};

class AuthService {
  setAxiosInterceptors = ({onLogout}) => {
    const csrf_token = this.getAccessToken();
    axios.interceptors.request.use(function (config) {
      if (["post", "delete", "patch", "put", "get"].includes(config["method"])) {
        if (csrf_token !== '') {
          //config.headers["X-CSRF-Token"] = csrf_token;
        }
      }
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (!error.response) {
            Sentry.captureException(error, {
              tags: {
                type: "UI Unknown"
              },
            });
          } else if (!_.isObject(error.response.data) || !error.response.data.message || !error.response.data.errors || !error.response.data.schema_errors) {
            Sentry.captureMessage('Unknown error', {
              tags: {
                type: "UI Unknown"
              },
            });
            Sentry.captureException(error, {
              tags: {
                type: "UI Unknown"
              },
            });
          }
          if (error.response && error.response.status === 401) {
            this.setSession(null);

            if (onLogout) {
              onLogout();
            }
          }

          return Promise.reject(error);
        }
    );
  };

  updateProfile = (update) => new Promise((resolve, reject) => {
    return axios.post('/a/api/v1/profile/', update).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  updatePassword = (password) => new Promise((resolve, reject) => {
    return axios.post('/a/api/v1/profile/password', {password}).then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  getApiKey = () => new Promise((resolve, reject) => {
    return axios.get('/a/api/v1/profile/apikey').then(data => resolve(data)).catch(error => reject(mapError(error)));
  });
  resetApiKey = () => new Promise((resolve, reject) => {
    return axios.post('/a/api/v1/profile/apikey/reset').then(data => resolve(data)).catch(error => reject(mapError(error)));
  });

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }
    this.setSession(accessToken);
  }

  getUserDetails = () => new Promise((resolve, reject) => {
    if (this.getAccessToken()) {
      axios.get('/a/api/v1/profile/', config)
          .then((response) => {
            resolve(response.data);
          }).catch(error => reject(mapError(error)));
    }
  });

  loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {
    axios.post('/login', {email, password})
        .then((response) => {
          if(response.data.response.tf_required){
            reject({reason: '2faRequired' , token: response.data.response.csrf_token});
          }
          if (response.data.response.user) {
            this.setSession(response.data.response.csrf_token);
            resolve(response.data.response.user);
          } else {
            reject({data: {response: {message: response.data.response.error}}});
          }
        })
        .catch((error) => {
          reject(error.response);
        });
  });

  logout = async () => {
    this.setSession(null);
    storage.removeItem('persist:root');
    this.clearCacheData();
    await axios.get('/logout');
  }
  clearCacheData = () => {
    const caches = window.caches;
    if(!caches) return;
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  setSession = (accessToken) => {
    if (accessToken) {
      //localStorage.setItem('XSRF-TOKEN', accessToken);
      Cookies.set('XSRF-TOKEN', accessToken);
      //axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      //localStorage.removeItem('XSRF-TOKEN');
      Cookies.remove('XSRF-TOKEN')
      //delete axios.defaults.headers.common.Authorization;
    }
  }

  //getAccessToken = () => localStorage.getItem('XSRF-TOKEN');
  getAccessToken = () => Cookies.get('XSRF-TOKEN');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }
  isAuthenticated = (data) => !!this.getAccessToken()
  register = (data) => new Promise((resolve, reject) => {
    const url = '/a/api/v1/registration/';
    axios.post(url, data, config)
        .then(response => {
          if (response.data.response.user) {
            this.setSession(response.data.response.csrf_token);
            resolve(response.data.response.user);
          } else {
            reject({data: {response: {message: response.data.response.error}}});
          }
        })
        .catch(error => reject(error.response));
  })
  registerWithEmail = (data) => new Promise((resolve, reject) => {
    const url = '/a/api/v1/registration/email/verify';
    axios.post(url, data, config)
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
  validateToken = (token) => new Promise((resolve, reject) => {
    const url = '/a/api/v1/registration/token/validate';
    axios.post(url, {token}, config)
    .then(response => resolve(response))
    .catch(error => reject(error));
  })
  activateSubscription = () => new Promise((resolve, reject) => {
    const url = '/a/api/v1/registration/activate';
    axios.post(url, null, config)
    .then(response => resolve(response))
    .catch(error => reject(error));
  })
}

const authService = new AuthService();

export default authService;
