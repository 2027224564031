import axios, {mapError} from 'src/utils/axios';

const config = {
  credentials: "same-origin",
  mode: 'cors',
  headers: {
    'Accept': 'application/json'
  }
};

class GuestUserService {
  loadGuestUserAccounts = (pageIndex, pageSize, searchString, siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guestuser/`;
    const params = {
      page: pageIndex,
      per_page: pageSize,
      search: searchString
    };
    axios.get(url, {...config, params}).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  loadGuestUserById = (siteId, id)  => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guestuser/${id}`;
    axios.get(url, {...config}).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  })
  addGuestUserDevice = (siteId, guestUserId, guestmac) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guestuser/${guestUserId}/connect`;
    axios.post(url, {guestmac}, config)
    .then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  })

  deleteGuestUserAccountById = (siteId, id) => new Promise((resolve, reject) => {
    axios.delete(`/s/${siteId}/api/v1/guestuser/${id}`, config)
      .then(({data}) => resolve(data))
      .catch(error => reject(mapError(error)));
  });
  disconnectUser = (siteId, guestUserId, authId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guestuser/${guestUserId}/disconnect/${authId}`;
    axios.post(url, {}, config)
      .then(({data}) => resolve(data))
      .catch(error => reject(mapError(error)));
  });
  importUsers = (siteId, file) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guestuser/import`;
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      credentials: "same-origin",
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'content-type': 'multipart/form-data'
      }
    };
    axios.post(url, formData, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));

  });

  createUser = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guestuser/`;
    axios.post(url, data, config)
      .then(({data}) => resolve(data))
      .catch(error => reject(mapError(error)));
  });
  updateUser = (siteId, guestUserId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/guestuser/${guestUserId}`;
    axios.put(url, data, config)
      .then(({data}) => resolve(data))
      .catch(error => reject(mapError(error)));
  })
}

const guestUserService = new GuestUserService();
export default guestUserService;
