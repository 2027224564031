import {SELECT_SITE} from "../actions/selectedSiteActions";

const selectedSiteReducer = (state = {}, action) => {
    switch (action.type) {
        case SELECT_SITE:
            return action.payload.site;
        default:
            return state;
    }
};

export default selectedSiteReducer;
