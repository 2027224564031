import axios, {mapError} from 'src/utils/axios';

const config = {
  credentials: "same-origin",
  mode: 'cors',
  headers: {
    'Accept': 'application/json'
  }
};

class PaymentTransactionsService {
  loadTransactions = (siteId, pageIndex, pageSize, searchString, fromDate, toDate) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymenttransactions/`;
    const params = {
      page: pageIndex,
      per_page: pageSize,
      search: searchString,
      fromdate: fromDate,
      todate: toDate
    };
    axios.get(url, {...config, params}).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  resendInvoiceService = (siteId, transactionId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymenttransactions/${transactionId}/resend/invoice`;
    axios.post(url, {}, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  updateTransaction = (siteId, transactionId, numberOfDevices, endTime) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymenttransactions/${transactionId}`;
    axios.put(url, {
      "num_devices": numberOfDevices,
      "endtime": endTime
    }, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  refundTransaction = (siteId, transactionId, disconnectDevices, reason_for_refund, comments) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/paymenttransactions/${transactionId}/refund`;
    axios.post(url, {
      "disconnect_devices": disconnectDevices,
      reason_for_refund,
      comments
    }, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });

}

const paymentTransactionsService = new PaymentTransactionsService();
export default paymentTransactionsService;
