export const ADD_FIELD = '@SplashPageAdvanced/addField';
export const RESET = '@SplashPageAdvanced/reset';
export const SET_VALUE = '@SplashPageAdvanced/setValue';
export const SET_FIELD_VALUE = '@SplashPageAdvanced/setFieldValue';
export const REMOVE_FIELD = '@SplashPageAdvanced/removeField';
export const REORDER_FIELD = '@SpashPageAdvanced/reorderField';
export const FIELDS = {
  FORMAT: "format",
  MANDATORY: "mandatory",
  DEFAULT_CHECKED: "default_checked",
  FEMALE_OPTION_TEXT: "female_option_text",
  MALE_OPTION_TEXT: "male_option_text",
  FIELD_TYPE: "field_type",
  REGEX: "regex",
  TITLE: "title",
};

export function setValue(name, value) {
  return ({
    type: SET_VALUE,
    payload: {name, value}
  });
}

export function reset() {
  return ({
    type: RESET
  });
}

export function addField(fieldType) {
  return ({
    type: ADD_FIELD,
    payload: fieldType
  });
}

export function removeField(index) {
  return ({
    type: REMOVE_FIELD,
    payload: {index}
  });
}

export function reorderField(sourceIndex, targetIndex) {
  return ({
    type: REORDER_FIELD,
    payload: {
      sourceIndex, targetIndex
    }
  });
}

export function setFieldValue(index, name, value) {
  return ({
    type: SET_FIELD_VALUE,
    payload: {
      index, name, value
    }
  });
}

export function getAdvancedActions(dispatch) {
  const setSessionLimitControl = (value) => dispatch(setValue('sessionLimitControl', value));
  const setEnableGracePeriod = (value) => dispatch(setValue('enableGracePeriod', value));
  const setGracePeriodDuration = (value) => dispatch(setValue('gracePeriodDuration', value));
  const setSessionOverridePass = (value) => dispatch(setValue('sessionOverridePassword', value));
  const setId = (value) => dispatch(setValue('id', value));
  const setUnlimitedSpeed = (value) => dispatch(setValue('unlimited_speed', value));
  const setSpeedUl = (value) => dispatch(setValue('speedUploadLimit', value));
  const setSpeedDl = (value) => dispatch(setValue('speedDownloadLimit', value));
  const setLoginFields = (value) => dispatch(setValue('fields', value));
  const setTimeLimit = (value) => dispatch(setValue('timeLimit', value));
  const setEnableValidation = (value) => dispatch(setValue('enableValidation', value));
  const setDataLimit = (value) => dispatch(setValue('dataLimit', value));
  const setReloginPolicy = (value) => dispatch(setValue('reLoginPolicy', value));
  const setSmstext = (value) => dispatch(setValue('smsText', value));
  const setDailyTrialLimit = (value) => dispatch(setValue('dailyTrialLimit', value));
  const setEnableVerification = (value) => dispatch(setValue('enableVerification', value));
  const setCollectBirthDay = (value) => dispatch(setValue('collectBirthday', value));
  const setFbPage = (value) => dispatch(setValue('fbPage', value));
  const setAuthFbPost = (value) => dispatch(setValue('authFbPost', value));
  const setCollectGender = (value) => dispatch(setValue('collectGender', value));
  const setCollectLocation = (value) => dispatch(setValue('collectLocation', value));
  const setMinAllowedAge = (value) => dispatch(setValue('minAllowedAge', value));
  const setBirthdayFormat = (value) => dispatch(setValue('birthdayFormat', value));
  const setFrequency = (value) => dispatch(setValue('frequency', value));
  const setSelfSignup = (value) => dispatch(setValue('self_signup', value));
  const setDurationType = (value) => dispatch(setValue('duration_type', value));
  const setBytes = (value) => dispatch(setValue('bytes_t', value));
  const setBytestype = (value) => dispatch(setValue('bytes_type', value));
  const setNumberOfDevices = (value) => dispatch(setValue('num_devices', value));
  const setDuration = (value) => dispatch(setValue('duration_val', value));
  const setUnlimitedData = (value) => dispatch(setValue('unlimited_data', value));
  const setLang = (value) => dispatch(setValue('lang', value));
  const setNetworkId = (value) => dispatch(setValue('network_id', value));
  const setSelfTest = (value) => dispatch(setValue('selftest', value));
  const setEnableUsageNotifications = (value) => dispatch(setValue('enable_usage_notifications', value));
  const setUsageNotificationsToSelf = (value) => dispatch(setValue('usage_notification_to_self', value));
  const setUsageNotificationExtraEmails = (value) => dispatch(setValue('usage_notification_extra_emails', value));
  const setEnableExpiryNotifications = (value) => dispatch(setValue('enable_expiry_notifications', value));
  const setExpiryNotificationsToSelf = (value) => dispatch(setValue('expiry_notification_to_self', value));
  const setExpiryNotificationExtraEmails = (value) => dispatch(setValue('expiry_notification_extra_emails', value));
  const initializeAdvancedFromData = (data) => {
    reset();
    setSessionLimitControl(data.session_limit_control);
    setEnableGracePeriod(data.enable_grace_period);
    setSessionOverridePass(data.session_overridepass);
    setId(data.id);
    setSpeedUl(data.speed_ul);
    setSpeedDl(data.speed_dl);
    setLoginFields(data.login_fields || []);
    setTimeLimit(data.time_limit);
    setGracePeriodDuration(data.grace_period_duration);
    setDataLimit(data.data_limit);
    setReloginPolicy(data.relogin_policy);
    setEnableVerification(data.enable_verification);
    setSmstext(data.smstext);
    setDailyTrialLimit(data.dailytriallimit);
    setEnableValidation(data.enable_validation);
    setCollectBirthDay(data.collect_birthday);
    setFbPage(data.fb_page);
    setAuthFbPost(data.auth_fb_post);
    setCollectGender(data.collect_gender);
    setCollectLocation(data.collect_location);
    setUnlimitedSpeed(data.unlimited_speed || (data.speed_dl === 0 && data.speed_ul === 0));
    setMinAllowedAge(data.min_allowed_age || 18);
    setFrequency(data.frequency);
    setBirthdayFormat(data.birthday_format);
    setSelfSignup(data.self_signup);
    setDurationType(data.duration_type);
    setBytes(data.bytes_t);
    setBytestype(data.bytes_type);
    setNumberOfDevices(data.num_devices);
    setDuration(data.duration_val);
    setUnlimitedData(data.unlimited_data);

    setSelfTest(data.selftest);
    setLang(data.lang);
    setNetworkId(data.network_id);

    setEnableUsageNotifications(data.enable_usage_notifications);
    setUsageNotificationsToSelf(data.usage_notification_to_self);
    setUsageNotificationExtraEmails(data.usage_notification_extra_emails);

    setEnableExpiryNotifications(data.enable_expiry_notifications);
    setExpiryNotificationsToSelf(data.expiry_notification_to_self);
    setExpiryNotificationExtraEmails(data.expiry_notification_extra_emails);
  };
  return {
    setSessionLimitControl,
    setEnableGracePeriod,
    setGracePeriodDuration,
    setSessionOverridePass,
    setId,
    setSpeedUl,
    setSpeedDl,
    setLoginFields,
    setTimeLimit,
    setEnableValidation,
    setDataLimit,
    setReloginPolicy,
    setSmstext,
    setDailyTrialLimit,
    setEnableVerification,
    setCollectBirthDay,
    setFbPage,
    setAuthFbPost,
    setCollectGender,
    setCollectLocation,
    initializeAdvancedFromData,
    setUnlimitedSpeed,
    setMinAllowedAge,
    setFrequency,
    setBirthdayFormat,
    setSelfSignup,
    setDurationType,
    setBytes,
    setBytestype,
    setNumberOfDevices,
    setDuration,
    setUnlimitedData
  };
}
