import {
  LOAD_USER_ACCOUNTS, LOAD_USER_ACCOUNTS_SUCCESS, LOAD_USER_ACCOUNTS_FAILURE
} from "../actions/guestUserActions";

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  metadata: {}
};
const guestUserAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER_ACCOUNTS:
      return {...state, error: '', isLoading: true};
    case LOAD_USER_ACCOUNTS_SUCCESS:
      return {...state, isLoading: false, items: action.payload.items, metadata: action.payload.metadata};
    case LOAD_USER_ACCOUNTS_FAILURE:
      return {...state, isLoading: false, error: action.payload.error};
    default:
      return state;
  }
};
export default guestUserAccountsReducer;
