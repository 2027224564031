import {LOAD_PACKAGES, LOAD_PACKAGES_FAILURE, LOAD_PACKAGES_START} from "../actions/packageActions";

const initialState = {
  packages: [],
  isLoading: false,
  error: ''
};
const packageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PACKAGES_START:
      return {...initialState, isLoading: true};
    case LOAD_PACKAGES:
      return {...state, packages: action.payload, isLoading: false};
    case LOAD_PACKAGES_FAILURE:
      return {...state, isLoading: false, error: 'Failed to load packages'};
    default:
      return state;
  }
};

export default packageReducer;
