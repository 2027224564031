import axios, {mapError} from 'src/utils/axios';

const config = {
  credentials: "same-origin",
  mode: 'cors',
  headers: {
    'Accept': 'application/json'
  }
};

class WhitelistedDevicesService {
  loadAllWhitelistedDevices = (pageIndex, pageSize, searchString, siteId) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/macentry/`;
    const params = {
      page: pageIndex,
      per_page: pageSize,
      search: searchString,
    };
    axios.get(url, {...config, params}).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });

  deleteWhitelistedDevice = (siteId, id) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/macentry/${id}`;
    axios.delete(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  addDeviceToWhitelist = (siteId, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/macentry/`;
    axios.post(url, data, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  editWhitelistedDevice = (siteId, id, data) => new Promise((resolve, reject) => {
    const url = `/s/${siteId}/api/v1/macentry/${id}`;
    axios.put(url, data, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
}

const whitelistedDevicesService = new WhitelistedDevicesService();
export default whitelistedDevicesService;
