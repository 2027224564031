import { DASHBOARD_LOAD_LAYOUT, DASHBOARD_LOAD_LAYOUT_FAILURE, DASHBOARD_LOAD_LAYOUT_SUCCESS, DASHBOARD_SAVE_LAYOUT, DASHBOARD_SAVE_LAYOUT_FAILURE, DASHBOARD_SAVE_LAYOUT_SUCCESS, DASHBOARD_SET_LAYOUT, DASHBOARD_TOGGLE_EDIT_MODE } from "src/actions/dashboardActions";

const initialState = {
  editState: false,
  layouts: [],
  loading: false,
  saveInProgress: false,
  error: ''
}
const dashboardReducer = (state = initialState, action) => {
  switch(action.type){
    case DASHBOARD_TOGGLE_EDIT_MODE:
      return {...state, editState: !state.editState};
    case DASHBOARD_SET_LAYOUT: 
      return {...state, layouts: action.layout};
    case DASHBOARD_LOAD_LAYOUT: 
      return {...state, loading: true, error: '', layouts: [], editState: false};
    case DASHBOARD_LOAD_LAYOUT_SUCCESS: 
      return {...state, loading: false, layouts: action.data.layout};
    case DASHBOARD_LOAD_LAYOUT_FAILURE: 
      return {...state, loading: false, error: 'Something went wrong!'};
    case DASHBOARD_SAVE_LAYOUT:
      return { ...state, saveInProgress: true};
    case DASHBOARD_SAVE_LAYOUT_SUCCESS:
      return { ...state, layouts: action.layout, editState: false, saveInProgress: false};
    case DASHBOARD_SAVE_LAYOUT_FAILURE:
      return { ...state, saveInProgress: false};
    default:
      return state;
  }
}
export default dashboardReducer;