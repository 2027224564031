import {
  DELETE_WHITELIST_FAILURE,
  LOAD_WHITELIST,
  LOAD_WHITELIST_FAILURE,
  LOAD_WHITELIST_SUCCESS
} from "../actions/whitelistActions";

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  metadata: {}
};
const whitelistReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_WHITELIST:
      return {...initialState, isLoading: true};
    case LOAD_WHITELIST_SUCCESS:
      return {...state, items: action.payload.items, metadata: action.payload.metadata, isLoading: false, error: ''};
    case LOAD_WHITELIST_FAILURE:
    case DELETE_WHITELIST_FAILURE:
      return {...state, error: action.payload.error, isLoading: false};
    default:
      return state;
  }
};
export default whitelistReducer;
