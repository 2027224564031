import { toast } from "react-toastify";
import dashboardService from "src/services/dashboardService";

export const DASHBOARD_TOGGLE_EDIT_MODE = '@Dashboard/ToggleEditMode';
export const DASHBOARD_SET_LAYOUT = '@Dashboard/SetLayout';
export const DASHBOARD_LOAD_LAYOUT = '@Dashboard/LoadLayout';
export const DASHBOARD_LOAD_LAYOUT_SUCCESS = '@Dashboard/LoadLayoutSuccess';
export const DASHBOARD_LOAD_LAYOUT_FAILURE = '@Dashboard/LoadLayoutFailure';
export const DASHBOARD_SAVE_LAYOUT = '@Dashboard/SaveLayout';
export const DASHBOARD_SAVE_LAYOUT_SUCCESS = '@Dashboard/SaveLayoutSuccess';
export const DASHBOARD_SAVE_LAYOUT_FAILURE = '@Dashboard/SaveLayoutFailure';
export function toggleEditLayoutMode(){
  return {
    type: DASHBOARD_TOGGLE_EDIT_MODE
  };
}
export function loadLayout(site) {
  return (dispatch) => {
    dispatch({type: DASHBOARD_LOAD_LAYOUT});
    dashboardService.getDashboardLayout(site)
    .then(data => dispatch({type: DASHBOARD_LOAD_LAYOUT_SUCCESS, data}))
    .catch(error => dispatch({type: DASHBOARD_LOAD_LAYOUT_FAILURE}));
  }
}
export function setLayout(layout){
  return {type: DASHBOARD_SET_LAYOUT, layout};
}
export function saveLayout(site, payload){
  return (dispatch) => {
    dispatch({type: DASHBOARD_SAVE_LAYOUT});
    dashboardService.setDashboardLayout(site, payload)
            .then(data => {
              dispatch({type: DASHBOARD_SAVE_LAYOUT_SUCCESS, layout: data.layout});
            }).catch(error => {
              dispatch({type: DASHBOARD_SAVE_LAYOUT_FAILURE})
              toast.error("Error occured while saving the layout");
            });
  }

}