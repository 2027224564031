import axios, {mapError} from 'src/utils/axios';
import moment from "moment";

const config = {
  credentials: "same-origin",
  mode: 'cors',
  headers: {
    'Accept': 'application/json'
  }
};
const dateQuery = (fromDate, toDate) => `?fromdate=${fromDate}&todate=${toDate}`;

class DashboardService {
  getData = (url, from, to) => new Promise((resolve, reject) => {
    const fromDate = moment(from).format('DD/MM/YYYY');
    const toDate = moment(to).format('DD/MM/YYYY');
    axios.get(url + dateQuery(fromDate, toDate), config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
  getDataFromUrl = (url) => new Promise((resolve, reject) => {
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });

  getWifiLogins = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/logins/graph' : `/s/${site.id}/api/v1/dashboard/logins/graph`, fromDate, toDate);
  getDailyTransactions = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/payment/get_daily_transactions/' : `/s/${site.id}/api/v1/dashboard/payment/get_daily_transactions/`, fromDate, toDate);
  getPopularPackages = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/payment/get_most_transaction_package/' : `/s/${site.id}/api/v1/dashboard/payment/get_most_transaction_package/`, fromDate, toDate);
  getLatestTransactions = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/payment/get_latest_transactions/' : `/s/${site.id}/api/v1/dashboard/payment/get_latest_transactions/`, fromDate, toDate);
  getSegmentData = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/segments/pie' : `/s/${site.id}/api/v1/dashboard/segments/pie`, fromDate, toDate);
  getActivityGraph = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/acitivity/graph' : `/s/${site.id}/api/v1/dashboard/acitivity/graph`, fromDate, toDate);
  getGenderData = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/gender/pie' : `/s/${site.id}/api/v1/dashboard/gender/pie`, fromDate, toDate);
  getLatestGuests = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/latestguests/table' : `/s/${site.id}/api/v1/dashboard/latestguests/table`, fromDate, toDate);
  getBounceRate = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/bounce_rate' : `/s/${site.id}/api/v1/dashboard/tile/bounce_rate`, fromDate, toDate);
  getRecurringRevenue = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/payment/recurring_amount/' : `/s/${site.id}/api/v1/dashboard/payment/recurring_amount/`, fromDate, toDate);
  getPaymentsCollected = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/payment/total_amount/' : `/s/${site.id}/api/v1/dashboard/payment/total_amount/`, fromDate, toDate);
  getActiveVouchers = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/voucher/num_active_vouchers/' : `/s/${site.id}/api/v1/dashboard/voucher/num_active_vouchers/`, fromDate, toDate);
  getUnusedVouchers = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/voucher/num_unused_vouchers/' : `/s/${site.id}/api/v1/dashboard/voucher/num_unused_vouchers/`, fromDate, toDate);
  getExpiringVouchers = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/voucher/num_about_to_expire/' : `/s/${site.id}/api/v1/dashboard/voucher/num_about_to_expire/`, fromDate, toDate);
  getActivatedVouchers = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/voucher/num_vouchers_activated/' : `/s/${site.id}/api/v1/dashboard/voucher/num_vouchers_activated/`, fromDate, toDate);
  getFirstTimeGuests = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/onlyonce' : `/s/${site.id}/api/v1/dashboard/tile/onlyonce`, fromDate, toDate);
  getDwellTime = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/dwell_time' : `/s/${site.id}/api/v1/dashboard/tile/dwell_time`, fromDate, toDate);
  getOnlineNow = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/online_now' : `/s/${site.id}/api/v1/dashboard/tile/online_now`, fromDate, toDate);
  getTotalGuests = (site, fromDate, toDate) => this.getData(site.id === 0 ? '/a/api/v1/dashboard/total_guests' : `/s/${site.id}/api/v1/dashboard/tile/total_guests`, fromDate, toDate);
  getDashboardLayout = (site) => this.getDataFromUrl(site.id === 0 ? '/a/api/v1/dashboard/total_guests' : `/s/${site.id}/api/v1/dashboard/config`);
  setDashboardLayout = (site, data) => new Promise((resolve, reject)=> axios.post(`/s/${site.id}/api/v1/dashboard/config`, data, config)
  .then(({data})=> resolve(data))
  .catch(error => reject(mapError(error))));
  doRealityCheck = (siteId)=> this.getData(`/s/${siteId}/api/v1/dashboard/data/real`);
}

const dashboardService = new DashboardService();

export default dashboardService;
