import {RESET, SET_VALUE} from "./splashPageAdvancedActions";
export const SET_ACCOUNT_FIELD_VALUE = '@SplashPageAdvanced/setAccountFieldValue';

export const FIELDS = {
  FORMAT: "format",
  MANDATORY: "mandatory",
  DEFAULT_CHECKED: "default_checked",
  FEMALE_OPTION_TEXT: "female_option_text",
  MALE_OPTION_TEXT: "male_option_text",
  FIELD_TYPE: "field_type",
  REGEX: "regex",
  TITLE: "title",
};

export function setValue(name, value) {
  return ({
    type: SET_VALUE,
    payload: {name, value}
  });
}

export function reset() {
  return ({
    type: RESET
  });
}

export function setAccountFieldValue(index, name, value) {
  return ({
    type: SET_ACCOUNT_FIELD_VALUE,
    payload: {
      index, name, value
    }
  });
}

export function getPaymentSettingsActions(dispatch) {
  const setSmsNotification = (value) => dispatch(setValue('smsNotification', value));
  const setNotifyEmail = (value) => dispatch(setValue('notifyEmail', value));
  const setLiveMode = (value) => dispatch(setValue('liveMode', value));
  const setEmailAccountNotify = (value) => dispatch(setValue('emailAccountNotify', value));
  const setConnectedToStripe = (value) => dispatch(setValue('connectedToStripe', value));
  const setShowStripeKeys = (value) => dispatch(setValue('showStripeKeys', value));
  const setStripeSecretKey = (value) => dispatch(setValue('stripeSecretKey', value));
  const setStripePublicKey = (value) => dispatch(setValue('stripePublicKey', value));
  const setAccountSmsText = (value) => dispatch(setValue('accountSmsText', value));
  const setSmsExpiryReminder = (value) => dispatch(setValue('smsExpiryReminder', value));
  const setEmailExpiryReminder = (value) => dispatch(setValue('emailExpiryReminder', value));
  const setNotifyWebhook = (value) => dispatch(setValue('notifyWebhook', value));
  const setAccountLoginFields = (value) => dispatch(setValue('accountLoginFields', value));
  const setId = (value) => dispatch(setValue('id', value));
  const setStripeConnectionUrl = (value) => dispatch(setValue('stripeConnectionUrl', value));
  const setWebhookAccountNotify = (value) => dispatch(setValue('webhookAccountNotify', value));
  const setLoginFields = (value) => dispatch(setValue('fields', value));
  const setExpiryReminderText = (value) => dispatch(setValue('expiryReminderText', value));

  const initializeAdvancedFromData = (data) => {
    reset();
    setSmsNotification(data.smsnotification);
    setLiveMode(data.livemode);
    setNotifyEmail(data.notify_email);
    setEmailAccountNotify(data.email_accnt_notify);
    setConnectedToStripe(data.connected_to_stripe);
    setShowStripeKeys(data.show_stripe_keys);
    setStripeSecretKey(data.payment_auth_field1);
    setStripePublicKey(data.payment_auth_field2);
    setAccountSmsText(data.accountsmstext);
    setSmsExpiryReminder(data.smsexpiryreminder);
    setEmailExpiryReminder(data.emailexpiryreminder);
    setNotifyWebhook(data.notify_webhook);
    setAccountLoginFields(data.account_login_fields);
    setId(data.id);
    setStripeConnectionUrl(data.stripe_connection_url);
    setWebhookAccountNotify(data.webhook_accnt_notify);
    setLoginFields(data.login_fields);
    setExpiryReminderText(data.notify_email);
  };
  return {
    setSmsNotification,
    setLiveMode,
    setNotifyEmail,
    setEmailAccountNotify,
    setConnectedToStripe,
    setShowStripeKeys,
    setAccountSmsText,
    setSmsExpiryReminder,
    setEmailExpiryReminder,
    setNotifyWebhook,
    setAccountLoginFields,
    setId,
    setStripeConnectionUrl,
    setWebhookAccountNotify,
    setLoginFields,
    setExpiryReminderText,
    initializeAdvancedFromData,
  };
}
