import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Hidden,
  IconButton,
  SvgIcon,
  Toolbar,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import { Refresh } from "@mui/icons-material";
import clsx from 'clsx';
import moment from "moment";
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Menu as MenuIcon, Sliders } from 'react-feather';
import { Trans, useTranslation } from "react-i18next";
import { IntercomAPI } from "react-intercom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Intercomm from 'src/components/Intercomm';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import { updateSite } from "../../../actions/selectedSiteActions";
import { loadSubscription } from "../../../actions/subscriptionActions";
import UpgradeAction from "../../../components/UpgradeAction";
import { PERMISSIONS } from "../../../config/permissions";
import subscriptionService from "../../../services/subscriptionService";
import { errorMessageToString } from "../../../utils/axios";
import Account from './Account';
import Sites from "./Sites";
import { useTheme } from '@emotion/react';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT ? {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[10],
    } : {}),
    ...(theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.paper
    } : {})
  },
  toolbar: {
    minHeight: 64
  },
  banner: {
    backgroundColor: '#ffff00',
    border: '1px solid #ddd',
    color: theme.palette.primary.main
  },
  expireBanner: {
    backgroundColor: '#ff1500',
    border: '1px solid #ddd',
    color: theme.palette.white
  }
}));

function TopBar({
                  className,
                  isSplashpage,
                  onMobileNavOpen,
                  ...rest
                }) {
  const classes = useStyles();
  const theme = useTheme();

  const {currentPlan} = useSelector(state => state.subscription);
  const isTrial = currentPlan?.status === 'trialing';
  const daysToExpiryForTrial = moment.utc(currentPlan?.trial_end).diff(moment.utc(), 'days');
  const daysToExpiry = moment.utc(currentPlan?.current_period_end).diff(moment.utc(), 'days');
  const account = useSelector((state) => state.account);
  const meta = useSelector(state => state.global);
  const selectedSite = useSelector(state => state.selectedSite);
  const [reactivating, setReactivating] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();


  function handleSelect() {
    history.push('/change-subscription/reactivate')
  }

  useEffect(() => {
    if(meta?.ui_settings?.INTERCOMM_APP_ID){
      IntercomAPI('boot', {
        app_id: meta.ui_settings.INTERCOMM_APP_ID,
        email: account.user.email,
        name: account.user.displayname,
        user_hash: account.user.intercom_hash,
        created_at: new Date().getUTCDate(),
      });
    }
  }, []);


  const handleControlPanel = () => {
    dispatch(updateSite({id: 0, name: 'Control Panel'}));
    history.push('/app/admins')
  };
  const menuButton = isSplashpage ? <IconButton
  className={classes.menuButton}
  color="primary"
  sx={{mr:5}}
  onClick={onMobileNavOpen}
  size="large">
  <SvgIcon fontSize="medium">
    <MenuIcon/>
  </SvgIcon></IconButton>: <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="primary"
            onClick={onMobileNavOpen}
            size="large">
            <SvgIcon fontSize="small">
              <MenuIcon/>
            </SvgIcon>
          </IconButton>
        </Hidden>;

  return (
    <AppBar
        className={clsx(classes.root, className)}
        {...rest}
    >
      <Intercomm />
      <Toolbar className={classes.toolbar}>
        {menuButton}
        <Hidden lgDown>
          <RouterLink to="/">
            <Logo url={meta.logo_svg}/>
          </RouterLink>
        </Hidden>
        <Box
            ml={2}
            flexGrow={1}
        />
        {isTrial && <Typography variant="h4">
          <Box className={classes.banner} p={1} m={1} borderRadius="5px">
            <Trans>Psst... your trial ends in</Trans> {daysToExpiryForTrial} <Trans>days</Trans> <UpgradeAction/>
          </Box>
        </Typography>}
        {currentPlan?.cancel_at_period_end && <Typography variant="h4">
          <Box className={classes.expireBanner} p={1} m={1} borderRadius="5px">
            <Trans>Psst... your subscription ends in</Trans> {daysToExpiry} <Trans>days</Trans>
                  <Button style={{marginLeft: 3}}
                          size="small" disabled={reactivating}
                          startIcon={reactivating ? <CircularProgress size={20} color="inherit"/> : <Refresh/>}
                          onClick={() => handleSelect()} variant="contained" color="primary">
                    <Trans>Reactivate</Trans>
                  </Button>
          </Box>
        </Typography>}
        {currentPlan?.status === 'cancelled' && <Typography variant="h4">
          <Box className={classes.expireBanner} p={1} m={1} borderRadius="5px">
            <Trans>Psst... your subscription is cancelled. Please reactivate.</Trans>
                 {' '} <Button style={{marginLeft: 3}}
                          size="small" disabled={reactivating}
                          startIcon={reactivating ? <CircularProgress size={20} color="inherit"/> : <Refresh/>}
                          onClick={() => handleSelect()} variant="contained" color="primary">
                    <Trans>Reactivate</Trans>
                  </Button>
          </Box>
        </Typography>}
        {account?.user?.permissions[PERMISSIONS.CONTROL_PANEL] && <Box ml={2}>
          {selectedSite.id !== 0 && <Button
              display="flex"
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<Sliders/>}
              onClick={handleControlPanel}
          >
            <Typography
                variant="h6"
                color="inherit"
            >
              <Trans>Control Panel</Trans>
            </Typography>
          </Button>}
        </Box>}
        <Box ml={2}>
          <Sites/>
        </Box>
        <Box ml={2}>
          <Account/>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
