import axios, {mapError} from "../utils/axios";
const config = {
  credentials: "same-origin",
  mode: 'cors',
  headers: {
    'Accept': 'application/json'
  }
};
class MetaService {
  getMetaData = () => new Promise((resolve, reject) => {
    const url = '/a/api/v1/meta/';
    axios.get(url, config).then(({data}) => {
      resolve(data);
    }).catch(error => reject(mapError(error)));
  });
}

const metaService = new MetaService();
export default metaService;
