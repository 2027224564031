export const UPDATE_CAMPAIGN = '@SplashAds/Update';
export const RESET_CAMPAIGN = '@SplashAds/Reset';

export function updateCampaign(delta) {
  return (dispatch) => dispatch({
    type: UPDATE_CAMPAIGN,
    payload: delta
  });
}

export function resetCampaign() {
  return ({type: RESET_CAMPAIGN});
}

