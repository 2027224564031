import {
  AdsClick,
  ChromeReaderMode,
  ContactMail,
  Devices,
  Email,
  People,
} from "@mui/icons-material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Bold,
  List as ListIcon,
  Package,
  PieChart as PieChartIcon,
  Settings,
} from "react-feather";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PERMISSIONS } from "src/config/permissions";
import AdminIcon from "../../components/AdminIcon";
import GuestsIcon from "../../components/GuestsIcon";
import TopBar from "../DashboardLayout/TopBar";
import { Divider, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const navConfig = [
  {
    inSiteOnly: true,
    permission: PERMISSIONS.ALLOW_ALL,
    title: "Dashboard",
    icon: <PieChartIcon/>,
    href: "/app/reports/dashboard",
    exact: true,
  },
  {
    inSiteOnly: false,
    dashboardOnly: true,
    permission: PERMISSIONS.ADMINS,

    title: "Admins",
    icon: <AdminIcon/>,
    href: "/app/admins",
    exact: false,
  },
  {
    inSiteOnly: false,
    dashboardOnly: true,
    permission: PERMISSIONS.CLIENTS,
    title: "Clients",
    icon: <People/>,
    href: "/app/clients",
    exact: false,
  },
  {
    inSiteOnly: false,
    dashboardOnly: true,
    permission: PERMISSIONS.CONTROL_PANEL,

    title: "Branding",
    icon: <Bold/>,
    href: "/app/branding",
    exact: false,
  },
  {
    inSiteOnly: true,
    permission: PERMISSIONS.LANDINGPAGE,

    title: " Splash Page",
    icon: <Devices/>,
    href: "/app/site/splash-pages",
    exact: false,
  },

  {
    inSiteOnly: true,
    subheader: "Campaigns",
    title: "Email Campaigns",
    icon: <Email/>,
    href: "/app/campaigns/email",
    exact: false,
    permission: PERMISSIONS.EMAIL_CAMPAIGNS,
  },
  {
    inSiteOnly: true,
    subheader: "Campaigns",
    title: "Splash page Ads",
    icon: <AdsClick/>,
    href: "/app/campaigns/splash-page-ad",
    exact: false,
    permission: PERMISSIONS.SPLASH_CAMPAIGNS,
  },
  {
    inSiteOnly: true,
    permission: PERMISSIONS.GUESTS,

    title: "Guests",
    icon: <GuestsIcon/>,
    href: "/app/guests",
    exact: false,
  },
  {
    inSiteOnly: true,
    permission: PERMISSIONS.MULTI_SITE_VOUCHER,
    title: "Multi-Site Vouchers",
    icon: <ChromeReaderMode/>,
    href: "/app/voucher/multisite/manage",
    exact: false,
  },
  {
    inSiteOnly: true,
    permission: PERMISSIONS.VOUCHER,
    title: "Vouchers",
    icon: <ChromeReaderMode/>,
    href: "/app/voucher/manage",
    exact: false,
  },

  {
    inSiteOnly: true,
    subheader: "Payments",
    permission: PERMISSIONS.PAYMENT,
    title: "Packages",
    icon: <Package/>,
    href: "/app/payment/packages",
    exact: false,
  },
  {
    inSiteOnly: true,
    subheader: "Payments",
    permission: PERMISSIONS.PAYMENT,
    title: "Transactions",
    icon: <ListIcon/>,
    href: "/app/payment/transactions",
    exact: false,
  },
  {
    inSiteOnly: true,
    subheader: "Payments",
    permission: PERMISSIONS.PAYMENT,
    title: "Accounts",
    icon: <People/>,
    href: "/app/payment/accounts",
    exact: false,
  },
  {
    inSiteOnly: true,
    permission: PERMISSIONS.GUESTUSER,

    title: "Guest Accounts",
    icon: <ContactMail/>,
    href: "/app/guestuser/manage",
    exact: false,
  },
  {
    inSiteOnly: true,
    permission: PERMISSIONS.SITESETTINGS,

    title: "Settings",
    icon: <Settings/>,
    href: "/app/settings-view",
    exact: false,
  },
];

export default function MiniDrawer({ children }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const selectedSite = useSelector((state) => state.selectedSite);
  const siteSettings = useSelector((state) => state.siteSettings);
  const { user } = useSelector((state) => state.account);
  const permissions = {
    [PERMISSIONS.ALLOW_ALL]: true,
    ...siteSettings?.settings?.permissions,
    ...user?.permissions,
  };

  const handleDrawerOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <TopBar />
      <Drawer variant="permanent" open={open}>
        <Toolbar/>
        <List>
          {navConfig
            .filter(
              (config) =>
                (selectedSite.id !== 0 && !config.dashboardOnly) ||
                (selectedSite.id === 0 && !config.inSiteOnly)
            )
            .filter(config => permissions[config.permission])
            .map((config, index) => (
              <ListItem key={config.title} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => history.push(config.href)}
                > 
                <Tooltip title={config.title} placement="right">
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {config.icon}
                  </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary={config.title} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
        <Divider />
        <DrawerHeader>
          <IconButton onClick={handleDrawerOpen}>
            {open ? <ChevronLeftIcon />: <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}
