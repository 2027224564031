import { REGISTRATION_SET_VALUE, REGISTRATION_INIT } from "src/actions/registrationActions";

const initialState = {
  account_name: "",
  account_url: "",
  password: "",
  deployment_type: "",
  deployment_type_other_text: "",
  account_type: "",
  displayname: "",
  plan_type: "",
  token: "",
  for_your_own: true
};
const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTRATION_INIT:
      return initialState;
    case REGISTRATION_SET_VALUE:
      return { ...state, [action.payload.name]: action.payload.value };
    default:
      return state;
  }
};
export default registrationReducer;
