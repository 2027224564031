import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

function AuthGuard({children}) {
  const account = useSelector((state) => state.account);
  const {currentPlan} = useSelector(state => state.subscription);
  const location = useLocation();
  if (!account.user) {
    return <Redirect to="/login"/>;
  }
  if (account.user.card_updated_required) {
    return  <Redirect to="/signup/registration/setup-payment/update"/>
  }
  if (!account.user.sub_active) {
    if(!currentPlan.plan_type){
      return <Redirect to="/change-subscription/update"/>
    }else if(account.user.billing_access&& location.pathname !=='/app/account/subscription'){
      return <Redirect to="/app/account/subscription"/>;
    }
  }
  if(account.user.site_creation_needed){
    return <Redirect to="/signup/registration/deployment/first" />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
