import {RESET_CAMPAIGN, UPDATE_CAMPAIGN} from "../actions/campaignActions";

const initialState = {
  templateId: null,
  name: '',
  subject: '',
  active: '',
  campaign_type: 'email',
  trigger_type: 'connect',
  trigger_param: 1,
  trigger_param_type: 'days',
  campaign_html: undefined,
  campaign_json: undefined,
  run_now: true,
  start_time: 0,
  end_time: 0,
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CAMPAIGN:
      return {...state, ...action.payload};
    case RESET_CAMPAIGN:
      return initialState;
    default:
      return state;
  }
};

export default campaignReducer;
