import {
  DELETE_UNUSED_MULTI_SITE_VOUCHERS,
  DELETE_UNUSED_MULTI_SITE_VOUCHERS_FAILURE,
  LOAD_UNUSED_MULTI_SITE_VOUCHERS,
  LOAD_UNUSED_MULTI_SITE_VOUCHERS_FAILURE,
  LOAD_UNUSED_MULTI_SITE_VOUCHERS_SUCCESS
} from "../actions/multiSiteVoucherActions";

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  metadata: {}
};
const voucherReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_UNUSED_MULTI_SITE_VOUCHERS:
    case DELETE_UNUSED_MULTI_SITE_VOUCHERS:
      return {...state,items: [], error: '', isLoading: true};
    case LOAD_UNUSED_MULTI_SITE_VOUCHERS_SUCCESS:
      return {...state, isLoading: false, items: action.payload.items, metadata: action.payload.metadata};
    case LOAD_UNUSED_MULTI_SITE_VOUCHERS_FAILURE:
    case DELETE_UNUSED_MULTI_SITE_VOUCHERS_FAILURE:
      return {...state, isLoading: false, error: action.payload.error};
    default:
      return state;
  }
};
export default voucherReducer;
