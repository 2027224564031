/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  ADD_SITE, LOGIN_2FA, LOGIN_FAILURE, LOGIN_REQUEST,
  LOGIN_SUCCESS, LOGOUT,
  SET_SITE_CREATION_NEEDED,
  SILENT_LOGIN,
  UPDATE_PROFILE
} from 'src/actions/accountActions';

const initialState = {
  user: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case LOGIN_SUCCESS: {
      const {user} = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
        draft.token = null;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }
    case LOGIN_2FA: {
      const {token} = action.payload;
      return produce(state, (draft) => {
        draft.token = token
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case SILENT_LOGIN: {
      const {user} = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case SET_SITE_CREATION_NEEDED: {
      const {value} = action.payload;
      return produce(state, (draft) => {
        draft.user.site_creation_needed = value;
      });
    }

    case UPDATE_PROFILE: {
      const {user} = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }
    case ADD_SITE: {
      const {site} = action.payload;
      return produce(state, (draft) => {
        draft.user.sites.push(site);
      })
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
