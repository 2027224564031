import {RESET_CAMPAIGN, UPDATE_CAMPAIGN} from "../actions/splashAdsActions";

const initialState = {
  templateId: null,
  autoskiptime: 0,
  run_now: true,
  splashad_html: undefined,
  autoskip: true,
  splashad_json: undefined,
  start_time: new Date(),
  active: 1,
  name: 'Untitled Campaign',
  trigger_type: 'always',
  end_time: new Date(),
  trigger_param: 0,
  rules_chaining: 'and',
  enable_rules: false,
  rules: []
};

const splashAdsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CAMPAIGN:
      return {...state, ...action.payload};
    case RESET_CAMPAIGN:
      return initialState;
    default:
      return state;
  }
};

export default splashAdsReducer;
