import {PERMISSIONS} from "../config/permissions";

export const SELECT_SITE = '@SelectedSite/select';

export function setSelectedSite(user) {
    return (dispatch) => dispatch({
        type: SELECT_SITE,
        payload: {
            site: user.sites[0]
        }
    });
}

export function updateSite(site) {
    return ({
        type: SELECT_SITE,
        payload: {
            site
        }
    })

}
