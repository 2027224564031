import React, {
  useEffect,
  useState
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import {setUserData, logout} from 'src/actions/accountActions';
import authService from 'src/services/authService';
import {setSelectedSite} from "../actions/selectedSiteActions";
import {useHistory} from "react-router";
import {loadSubscription} from "../actions/subscriptionActions";
import {loadSiteSettings} from "../actions/siteSettingsActions";
import * as Sentry from "@sentry/react";
import { createStyles } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LoadingScreen from './LoadingScreen';
import { posthog } from 'posthog-js';

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        width: "100%",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
      ".MuiTableCell-head": {
        fontWeight: 600,
      },
    },
  })
);
function Auth({children}) {
  useStyles();
  const dispatch = useDispatch();
  const selectedSite = useSelector(state => state.selectedSite);
  const siteSettings = useSelector(state => state.siteSettings);
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();
  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => {
          dispatch(logout());
          setLoading(false);
          history.push('/login');
        }
      });
      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        const user = await authService.getUserDetails();
        Sentry.setUser({ email: user.email, id: user.id });
        posthog.identify(user.email);
        await dispatch(setUserData(user));
        if (user.billing_access) {
          await dispatch(loadSubscription());
        }

        if (!selectedSite || selectedSite.length === 0) {
          await dispatch(setSelectedSite(user));
          if (!user.isProvider) {
            await dispatch(loadSiteSettings(user.sites[0].id));
          }
        } else if (selectedSite.id > 0) {
          await dispatch(loadSiteSettings(selectedSite.id));
        }
      } else if (!(window.location.pathname.indexOf('register') > -1 
      || window.location.pathname.indexOf('signup') > -1 
      || window.location.pathname.indexOf('plan') > -1 
      || window.location.pathname.indexOf('google')> -1)) {
        dispatch(logout());
        setLoading(false);
        history.push('/login');
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch, history, selectedSite]);

  if (authService.isAuthenticated() && siteSettings && siteSettings.isLoading) {
    return <LoadingScreen/>;
  }

  if (isLoading) {
    return <SplashScreen/>;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
