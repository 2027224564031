/* eslint-disable react/no-array-index-key */
import React, {Fragment, lazy, Suspense, useEffect} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import SiteGuard from "./components/SiteGuard";
import {PERMISSIONS} from "./config/permissions";
import PermissionGuard from "./components/PermissionGuard";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router";
import {updateSite} from "./actions/selectedSiteActions";
import {loadSiteSettings} from "./actions/siteSettingsActions";
import { toast } from 'react-toastify';
import SplashpageLayout from './layouts/SlashpageLayout';
import Logout from './views/auth/Logout';
import { exact } from 'prop-types';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/app"/>
  },
  {
    exact: true,
    path:'/logout',
    component: () => <Logout />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    path: '/google',
    component: lazy(() => import('src/views/auth/GoogleLogin'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/selectPlan',
    component: lazy(() => import('src/views/pages/SelectPlanView'))
  },
  {
    exact: true,
    path: '/change-subscription/:flow?',
    component: lazy(() => import('src/views/pages/ChangeSubscription'))
  },
  {
    exact: true,
    path: '/plan',
    component: lazy(() => import('src/views/auth/SelectPlan'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register/:plan?',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/signup/verification/:token?',
    component: lazy(() => import('src/views/auth/SignupWithToken'))

  },
  {
    exact: true,
    path: '/signup/registration/success',
    component: lazy(() => import('src/views/auth/SignupWithToken/Success'))
  },
  {
    exact: true,
    path: '/signup/registration/setup-payment/:flow?',
    component: lazy(() => import('src/views/auth/SignupWithToken/SetupPayment'))
  },
  {
    exact: true,
    path: '/signup/registration/deployment/1/:first?',
    component: lazy(() => import('src/views/pages/SiteCreateView/CreateSiteStep2'))
  },
  {
    exact: true,
    path: '/signup/registration/field-collect/:first?',
    component: lazy(() => import('src/views/pages/SiteCreateView/FieldCollection'))
  },
  {
    exact: true,
    path: '/signup/registration/branding-info/:first?',
    component: lazy(() => import('src/views/pages/SiteCreateView/BrandingInfo'))
  },
  {
    exact: true,
    path: '/signup/registration/payment-onboarding/:first?',
    component: lazy(() => import('src/views/pages/SiteCreateView/PaymentOnboarding'))
  },
  {
    exact: true,
    path: '/signup/registration/guestuser-onboarding/:first?',
    component: lazy(() => import('src/views/pages/SiteCreateView/GuestUserOnboarding'))
  },
  {
    exact: true,
    path: '/signup/registration/voucher-onboarding/:first?',
    component: lazy(() => import('src/views/pages/SiteCreateView/VoucherOnboarding'))
  },
  {
    exact: true,
    path: '/signup/registration/deployment/:first?',
    component: lazy(() => import('src/views/pages/SiteCreateView/RegistrationFlowDeployment'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/signup/registration/:plan?',
    component: lazy(() => import('src/views/auth/SignupWithToken/RegistrationFlowAccount'))
  },
  {
    exact: true,
    path: '/app/site/new/:first?',
    component: lazy(() => import('src/views/pages/SiteCreateView'))
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: '/app/account/:selectedTab?',
    guard: AuthGuard,
    component: lazy(() => import('src/views/pages/AccountView'))
  },
  {
    path:'/app/site/splash-pages',
    guard: AuthGuard,
    layout: SplashpageLayout,
    routes:[
      {
        exact: true,
        path: '/app/site/splash-pages',
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SplashPages')),
      },
    ]
  },
  {
    path:'/app/settings-view',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes:[{
      exact: true,
      path: '/app/settings-view',
      permission: PERMISSIONS.SITESETTINGS,
      component: lazy(() => import('src/views/pages/SettingsView'))
      },
      {
        exact: true,
        path: '/app/settings-view/temp',
        permission: PERMISSIONS.SITESETTINGS,
        component: lazy(() => import('src/views/pages/SettingsView'))
      },
      {
        exact: true,
        path: '/app/settings-view/splash/:screen',
        permission: PERMISSIONS.SITESETTINGS,
        component: lazy(() => import('src/views/pages/SettingsView/SplashSettings'))
      },
      {
        exact: true,
        path: '/app/settings-view/sms-config',
        permission: PERMISSIONS.SMSGATEWAY,
        component: lazy(() => import('src/views/pages/SettingsView/SMSConfiguration'))
      },{
        exact: true,
        path: '/app/settings-view/client-access',
        permission: PERMISSIONS.CLIENT_ACCESS,
        component: lazy(() => import('src/views/pages/SettingsView/ClientAccess'))
      },{
        exact: true,
        path: '/app/settings-view/whitelisted-devices',
        permission: PERMISSIONS.MACAUTH,
        component: lazy(() => import('src/views/pages/SettingsView/WhitelistedDevices'))
      },{
        exact: true,
        path: '/app/settings-view/site-name',
        permission: PERMISSIONS.SITESETTINGS,
        component: lazy(() => import('src/views/pages/SettingsView/UpdateSiteName'))
      },{
        exact: true,
        path: '/app/settings-view/redirect-url',
        permission: PERMISSIONS.SITESETTINGS,
        component: lazy(() => import('src/views/pages/SettingsView/RedirectUrl'))
      },{
        exact: true,
        path: '/app/settings-view/configure-reports',
        permission: PERMISSIONS.REPORTS,
        component: lazy(() => import('src/views/pages/SettingsView/ConfigureReports'))
      },{
        exact: true,
        path: '/app/settings-view/customer-portal',
        permission: PERMISSIONS.PAYMENT,
        component: lazy(() => import('src/views/pages/SettingsView/CustomerPortal'))
      },
      {
        exact: true,
        path: '/app/settings-view/unifi',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/UnifiControllerView'))
      },
      {
        exact: true,
        path: '/app/settings-view/unifi-scheduled-tasks',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/UnifiScheduledTasks'))
      },
      {
        exact: true,
        path: '/app/settings-view/mikrotik',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/Mikrotik'))
      },{
        exact: true,
        path: '/app/settings-view/arubainstanton',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/ArubaInstantOn'))
      },{
        exact: true,
        path: '/app/settings-view/cnmaestro',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/CnMaestro'))
      },{
        exact: true,
        path: '/app/settings-view/omada',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/Omada'))
      }, {
        exact: true,
        path: '/app/settings-view/openmesh',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/OpenMesh'))
      }, {
        exact: true,
        path: '/app/settings-view/ignitenet',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/Ignitenet'))
      }, {
        exact: true,
        path: '/app/settings-view/arubacentral',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/Arubacentral'))
      }, {
        exact: true,
        path: '/app/settings-view/zyxelnebula',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/Zyxel'))
      }, {
        exact: true,
        path: '/app/settings-view/ruckusunleashed',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/RuckusUnleashed'))
      }, {
        exact: true,
        path: '/app/settings-view/meraki',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/Meraki'))
      },{
        exact: true,
        path: '/app/settings-view/draytek',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/Draytek'))
      },{
        exact: true,
        path: '/app/settings-view/merakigo',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/MerakiGo'))
      }, {
        exact: true,
        path: '/app/settings-view/aruba',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/ArubaMBC'))
      }, {
        exact: true,
        path: '/app/settings-view/arubaiap',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/ArubaIAP'))
      }, {
        exact: true,
        path: '/app/settings-view/ciscowlc',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/CiscoWLC'))
      }, {
        exact: true,
        path: '/app/settings-view/ruckus_cloud',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/RuckusCloud'))
      },{
        exact: true,
        path: '/app/settings-view/mist',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/Mist'))
      }, {
        exact: true,
        path: '/app/settings-view/ruckus',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/RuckusZD'))
      }, {
        exact: true,
        path: '/app/settings-view/smartzone',
        permission: PERMISSIONS.ROUTERSETINGS,
        component: lazy(() => import('src/views/pages/DeviceConfigurations/Smartzone'))
      },
      {
        exact: true,
        path: '/app/settings-view/integrations',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations'))
      }, {
        exact: true,
        path: '/app/settings-view/integrations/inactive',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Inactive'))
      },
      {
        exact: true,
        path: '/app/settings-view/integrations/hubspot',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Hubspot'))
      },
      {
        exact: true,
        path: '/app/settings-view/integrations/hubspot/connection',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Hubspot/Connection'))
      },
      {
        exact: true,
        path: '/app/settings-view/integrations/campaignmonitor',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Campaignmonitor'))
      },{
        exact: true,
        path: '/app/settings-view/integrations/placewise',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Placewise'))
      }, {
        exact: true,
        path: '/app/settings-view/integrations/smartmembership',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/SmartMembership'))
      }, {
        exact: true,
        path: '/app/settings-view/integrations/dotdigital',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Dotdigital'))
      }, {
        exact: true,
        path: '/app/settings-view/integrations/emma',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Emma'))
      }, {
        exact: true,
        path: '/app/settings-view/integrations/mailchimp',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Mailchimp'))
      }, {
        exact: true,
        path: '/app/settings-view/integrations/revinate',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Revinate'))
      },{
        exact: true,
        path: '/app/settings-view/integrations/airship',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/AirShip'))
      },
      {
        exact: true,
        path: '/app/settings-view/integrations/access_crm',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Acteol'))
      },{
        exact: true,
        path: '/app/settings-view/integrations/access_crm/connection',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Acteol/Connection'))
      },{
        exact: true,
        path: '/app/settings-view/integrations/airship/connection',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/AirShip/Connection'))
      },{
        exact: true,
        path: '/app/settings-view/integrations/mailchimp/connection',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Mailchimp/Connection'))
      }, {
        exact: true,
        path: '/app/settings-view/integrations/mailjet',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Mailjet'))
      }, {
        exact: true,
        path: '/app/settings-view/integrations/mailrelay',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/MailRelay'))
      }, {
        exact: true,
        path: '/app/settings-view/integrations/msteams',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Teams'))
      }, {
        exact: true,
        path: '/app/settings-view/integrations/webhook',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Webhook'))
      }, {
        exact: true,
        path: '/app/settings-view/integrations/zonal',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Zonal'))
      }, {
        exact: true,
        path: '/app/settings-view/integrations/klaviyo',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Klaviyo'))
      }, {
        exact: true,
        path: '/app/settings-view/integrations/icontact',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/IContact'))
      },    {
        exact: true,
        path: '/app/settings-view/integrations/mryum/connection',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/MrYum/Connection'))
      },
      {
        exact: true,
        path: '/app/settings-view/integrations/mryum',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/MrYum'))
      },
      {
        exact: true,
        path: '/app/settings-view/integrations/customerio',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/CustomerIO'))
      },
      {
        exact: true,
        path: '/app/settings-view/integrations/access_crm',
        permission: PERMISSIONS.INTEGRATIONS,
        component: lazy(() => import('src/views/pages/Integrations/Acteol'))
      },
    ]
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: SplashpageLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard"/>
      },
      {
        exact: true,
        path: '/app/campaigns/email',
        permission: PERMISSIONS.EMAIL_CAMPAIGNS,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/Campaigns/Email'))
      }, {
        exact: true,
        path: '/app/campaigns/splash-page-ad',
        permission: PERMISSIONS.SPLASH_CAMPAIGNS,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/Campaigns/SplashPageAds'))
      }, {
        exact: true,
        path: '/app/campaigns/splash-page-ad/create',
        permission: PERMISSIONS.SPLASH_CAMPAIGNS,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/Campaigns/SplashPageAds/Create'))
      },

      {
        exact: true,
        path: '/app/guestuser/manage',
        permission: PERMISSIONS.GUESTUSER,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/GuestAccounts'))
      }, {
        exact: true,
        path: '/app/guestuser/manage/create',
        permission: PERMISSIONS.GUESTUSER,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/GuestAccounts/CreateEditGuestUser'))
      },
      {
        exact: true,
        path: '/app/guestuser/manage/edit/:id',
        permission: PERMISSIONS.GUESTUSER,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/GuestAccounts/CreateEditGuestUser'))
      },
      {
        exact: true,
        path: '/app/guestuser/manage/details/:id/:p/:rpp/:search?',
        permission: PERMISSIONS.GUESTUSER,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/GuestAccounts/UserDetails'))
      },
      {
        exact: true,
        path: '/app/voucher/multisite/manage/create',
        permission: PERMISSIONS.MULTI_SITE_VOUCHER,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/ManageVouchers/CreateVoucher'))
      },
      {
        exact: true,
        path: '/app/voucher/multisite/manage/design',
        permission: PERMISSIONS.MULTI_SITE_VOUCHER,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/ManageVouchers/DesignVoucher'))
      },
      {
        exact: true,
        path: '/app/voucher/multisite/manage/details/:id?',
        permission: PERMISSIONS.MULTI_SITE_VOUCHER,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/ManageVouchers/VoucherUsageDetails'))
      },
      {
        exact: true,
        path: '/app/voucher/multisite/manage',
        permission: PERMISSIONS.MULTI_SITE_VOUCHER,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/ManageVouchers'))
      },
      {
        exact: true,
        path: '/app/voucher/manage/create',
        permission: PERMISSIONS.VOUCHER,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/ManageVouchers/CreateVoucher'))
      },
      {
        exact: true,
        path: '/app/voucher/manage/design',
        permission: PERMISSIONS.VOUCHER,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/ManageVouchers/DesignVoucher'))
      },
      {
        exact: true,
        path: '/app/voucher/manage/details/:id?',
        permission: PERMISSIONS.VOUCHER,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/ManageVouchers/VoucherUsageDetails'))
      },
      {
        exact: true,
        path: '/app/voucher/manage',
        permission: PERMISSIONS.VOUCHER,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/ManageVouchers'))
      },

      {
        exact: true,
        path: '/app/payment/packages/create-trial',
        permission: PERMISSIONS.PAYMENT,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/ManagePackages/CreateEditTrialPackage'))
      },
      {
        exact: true,
        path: '/app/payment/packages/edit-trial/:id',
        permission: PERMISSIONS.PAYMENT,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/ManagePackages/CreateEditTrialPackage'))
      },
      {
        exact: true,
        path: '/app/payment/packages/create',
        permission: PERMISSIONS.PAYMENT,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/ManagePackages/CreateEditPackage'))
      },
      {
        exact: true,
        path: '/app/payment/packages/edit/:id',
        permission: PERMISSIONS.PAYMENT,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/ManagePackages/CreateEditPackage'))
      },
      {
        exact: true,
        path: '/app/payment/packages',
        permission: PERMISSIONS.PAYMENT,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/ManagePackages'))
      },
      {
        exact: true,
        path: '/app/payment/transactions',
        permission: PERMISSIONS.PAYMENT,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/ManageTransactions'))
      },
      {
        exact: true,
        path: '/app/payment/accounts',
        permission: PERMISSIONS.PAYMENT,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/ManageAccounts'))
      },
      {
        exact: true,
        path: '/app/payment/accounts/details/:id/:page/:rowsPerPage/:fromDate/:toDate/:search?',
        permission: PERMISSIONS.PAYMENT,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/SiteSettings/ManageAccounts/AccountDetails'))
      },

      {
        exact: true,
        path: '/app/campaigns/email/create',
        permission: PERMISSIONS.EMAIL_CAMPAIGNS,
        guard: SiteGuard,
        component: lazy(() => import('src/views/pages/Campaigns/Email/CreateCampaign'))
      },
      {
        exact: true,
        path: '/app/site/:siteId',
        component: lazy(() => import('src/views/pages/SiteEditView'))
      },
      {
        exact: true,
        path: '/app/guests/details/:id',
        permission: PERMISSIONS.GUESTS,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/ManageGuests/GuestDetails'))
      },
      {
        exact: true,
        path: '/app/guests',
        permission: PERMISSIONS.GUESTS,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/ManageGuests'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/admins',
        permission: PERMISSIONS.ADMINS,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/Admins'))
      },
      {
        exact: true,
        path: '/app/admins/create',
        permission: PERMISSIONS.ADMINS,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/Admins/CreateEditAdmin'))
      },
      {
        exact: true,
        path: '/app/admins/edit/:id',
        permission: PERMISSIONS.ADMINS,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/Admins/CreateEditAdmin'))
      },
      {
        exact: true,
        path: '/app/clients',
        permission: PERMISSIONS.CLIENTS,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/Clients'))
      },
      {
        exact: true,
        path: '/app/clients/create',
        permission: PERMISSIONS.CLIENTS,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/Clients/CreateEditClient'))
      },
      {
        exact: true,
        path: '/app/clients/edit/:id',
        permission: PERMISSIONS.CLIENTS,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/Clients/CreateEditClient'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile"/>
      },
       {
        exact: true,
        path: '/app/whitelist/create',
        permission: PERMISSIONS.MACAUTH,
        component: lazy(() => import('src/views/pages/SettingsView/Whitelist/CreateEditWhilelistedDevice'))
      }, {
        exact: true,
        path: '/app/whitelist/edit/:id',
        permission: PERMISSIONS.MACAUTH,
        component: lazy(() => import('src/views/pages/SettingsView/Whitelist/CreateEditWhilelistedDevice'))
      },
       {
        exact: true,
        path: '/app/branding',
        permission: PERMISSIONS.CONTROL_PANEL,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/Branding'))
      }, {
        exact: true,
        path: '/app/branding/logo',
        permission: PERMISSIONS.BRANDING,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/Branding/UpdateLogo'))
      }, {
        exact: true,
        path: '/app/branding/header',
        permission: PERMISSIONS.BRANDING,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/Branding/UpdateHeader'))
      }, {
        exact: true,
        path: '/app/branding/login-page-branding',
        permission: PERMISSIONS.BRANDING,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/Branding/LoginPageBranding'))
      }, {
        exact: true,
        path: '/app/branding/footer',
        permission: PERMISSIONS.BRANDING,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/Branding/UpdateFooter'))
      }, {
        exact: true,
        path: '/app/branding/splash-footer',
        permission: PERMISSIONS.BRANDING,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/Branding/SplashPageFooter'))
      }, {
        exact: true,
        path: '/app/branding/domain',
        permission: PERMISSIONS.BRANDING,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/Branding/WhitelistDomain'))
      }, {
        exact: true,
        path: '/app/branding/client-invite',
        permission: PERMISSIONS.BRANDING,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/Branding/ClientInvite'))
      }, {
        exact: true,
        path: '/app/branding/invoice',
        permission: PERMISSIONS.PAYMENT_BRANDING,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/Branding/Invoice'))
      }, {
        exact: true,
        path: '/app/branding/pay-account',
        permission: PERMISSIONS.PAYMENT_BRANDING,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/Branding/PayAccount'))
      }, {
        exact: true,
        path: '/app/branding/pay-expiry',
        permission: PERMISSIONS.PAYMENT_BRANDING,
        guard: PermissionGuard,
        component: lazy(() => import('src/views/pages/Branding/PayExpiry'))
      },
      {
        component: () => <Redirect to="/404"/>
      }
    ]
  },
];

const renderRoutes = (routes) => {

  return (routes ? (
      <Suspense fallback={<LoadingScreen/>}>
        <Switch>
          {routes.map((route, i) => {
            const Guard = route.guard || Fragment;
            const Layout = route.layout || Fragment;
            const Component = route.component;

            return (
                <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    render={(props) => {
                      if (route.guard) {
                        return (<Guard permission={route.permission}>
                              <Layout>
                                {route.routes
                                    ? renderRoutes(route.routes)
                                    : <Component {...props} />}
                              </Layout>
                            </Guard>
                        )
                      } else {
                        return (<Layout>
                          {route.routes
                              ? renderRoutes(route.routes)
                              : <Component {...props} />}
                        </Layout>);
                      }
                    }}
                />
            );
          })
          }
        </Switch>
      </Suspense>
  ) : null);
}

function Routes() {
  const siteSettings = useSelector(state => state.siteSettings);
  const selectedSite = useSelector(state => state.selectedSite);
  const account = useSelector(state => state.account);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    function appendSiteAsQueryParameter() {
      if(location.pathname.includes('google') 
      || location.pathname.includes('/app/site/new')
      || location.pathname.includes('/register')
      || location.pathname.includes('/plan')
      || location.pathname.includes('/signup')
      || location.pathname.includes('/app/site/new/first')){
        return;
      }
      const params = new URLSearchParams(location.search)

      function changeSite() {
        const siteId = new URLSearchParams(location.search).get('site');
        if (+siteId !== selectedSite.id) {
          const site = account.user?.sites?.filter(s => s.id === +siteId)[0];
          if (site) {
            dispatch(updateSite(site));
            if (site.id > 0) {
              dispatch(loadSiteSettings(site.id));
            }
          } else {
            toast.error('No site exist with site id ' + siteId);
            params.set("site", selectedSite.id)
            history.push({search: params.toString()})
          }
        }
      }

      if (selectedSite.id !== undefined && !params.has('site')) {
        params.set("site", selectedSite.id);
        history.push({search: params.toString()});
      } else if (params.has('site')) {
        changeSite();
      }
    }

    appendSiteAsQueryParameter();
    //eslint-disable-next-line
  }, [location]);

  return renderRoutes(routesConfig);
}

export default Routes;
