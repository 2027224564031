import voucherService from "../services/multiSiteVoucherService";
import {errorMessageToString} from "../utils/axios";

export const LOAD_UNUSED_MULTI_SITE_VOUCHERS = '@MultiSiteVouchers/unused/load';
export const LOAD_UNUSED_MULTI_SITE_VOUCHERS_SUCCESS = '@MultiSiteVouchers/unused/load/success';
export const LOAD_UNUSED_MULTI_SITE_VOUCHERS_FAILURE = '@MultiSiteVouchers/unused/load/failure';
export const DELETE_UNUSED_MULTI_SITE_VOUCHERS = '@MultiSiteVouchers/unused/delete';
export const DELETE_UNUSED_MULTI_SITE_VOUCHERS_FAILURE = '@MultiSiteVouchers/unused/delete/failure';

export function loadVouchers(pageIndex, pageSize, searchString, siteId, tab, sortOn, sortBy) {
  return (dispatch) => {
    dispatch({type: LOAD_UNUSED_MULTI_SITE_VOUCHERS});
    (tab === 0 ? voucherService.loadUnusedVouchers(pageIndex, pageSize, searchString, siteId, sortOn, sortBy): tab === 1 ?
        voucherService.loadUsedVouchers(pageIndex, pageSize, searchString, siteId, sortOn, sortBy)
      : voucherService.loadExpiredVouchers(pageIndex, pageSize, searchString, siteId, sortOn, sortBy)).then(data => {
      dispatch({
        type: LOAD_UNUSED_MULTI_SITE_VOUCHERS_SUCCESS,
        payload: {
          items: data.items,
          metadata: data.metadata
        }
      });
    }).catch(error => {
      dispatch({type: LOAD_UNUSED_MULTI_SITE_VOUCHERS_FAILURE, payload: {error: errorMessageToString(error)}})
    })
  }
}

export function deleteVouchers(url, tab, sortOn, search,sortBy, deleteCallback) {
  return (dispatch, getState) => {
    const {vouchers, selectedSite} = getState();
    dispatch({type: DELETE_UNUSED_MULTI_SITE_VOUCHERS});
    voucherService.deleteVouchers(url)
      .then(_ => {
        dispatch(loadVouchers(1, vouchers.metadata.per_page, search, selectedSite.id, tab, sortOn, sortBy));
        deleteCallback();
      })
      .catch(error => {
        console.log(error.statusText);
        dispatch({type: DELETE_UNUSED_MULTI_SITE_VOUCHERS_FAILURE, payload: {error: errorMessageToString(error)}})
      });

  };
}
export function deleteVoucher(id, tab, sortOn, sortBy, search) {
  return (dispatch, getState) => {
    const {vouchers, selectedSite} = getState();
    dispatch({type: DELETE_UNUSED_MULTI_SITE_VOUCHERS});
    voucherService.deleteVoucher(selectedSite.id, id)
      .then(_ => dispatch(loadVouchers(1, vouchers.metadata.per_page, search, selectedSite.id, tab, sortOn, sortBy)))
      .catch(error => {
        console.log(error.statusText);
        dispatch({type: DELETE_UNUSED_MULTI_SITE_VOUCHERS_FAILURE, payload: {error: errorMessageToString(error)}})
      });

  };
}
